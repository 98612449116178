






















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { BearerType, Role, RoleBinding } from '@aas-dashboard/repo-api/models'
import RoleStore from '@/store/modules/RoleStore'
import UserStore from '@/store/modules/UserStore'
import { AASTemplateDescriptor, Group, User } from '@aas-dashboard/repo-api'
import { templateRoles } from '@/components/admin/models'
import _ from 'lodash'
import AuthStore from '@/store/modules/AuthStore'
import { MyEventEmitter } from '@/event'
import GroupStore from '@/store/modules/GroupStore'

interface GroupMember {
  group: Group;
  role: Role;
  binding: RoleBinding;
  me: boolean;
}

interface Member {
  user: User;
  source: Group | 'direct' | null
  role: Role;
  binding: RoleBinding;
  me: boolean;
}

@Component({
  components: { }
})
export default class EditTemplatePermissionsModal extends Vue {
  @Prop() readonly dialog!: boolean
  @Prop() readonly template!: AASTemplateDescriptor
  @Prop() readonly myRoleBinding!: RoleBinding
  @Prop() readonly modalEvents!: MyEventEmitter

  Role: typeof Role = Role

  public groupMembersHeader = [
    { text: '', value: 'me' },
    { text: 'group', value: 'group.name' },
    { text: 'Role', value: 'role' },
    { text: '', value: 'controls', sortable: false, align: 'right' }
  ]

  public membersHeader = [
    { text: '', value: 'me' },
    { text: 'Name', value: 'user.displayName' },
    { text: 'Source', value: 'source' },
    { text: 'Role', value: 'role' },
    { text: '', value: 'controls', sortable: false, align: 'right' }
  ]

  public groupMembers: GroupMember[] = []
  public members: Member[] = []

  public roleBindings: RoleBinding[] = []

  get roles (): Role[] {
    return templateRoles
  }

  @Watch('template')
  public async onTemplatePropChange (): Promise<void> {
    if (this.template) {
      this.roleBindings = await RoleStore.getRoleBindingsTemplate(this.template.hash) ?? []

      // direct membership
      const userIds: Set<string> = new Set<string>()
      const direct = this.roleBindings.filter(binding => binding.bearer.bearerType === BearerType.User).map(binding => binding.bearer.bearerId)
      direct.forEach(userId => userIds.add(userId))

      // indirect / group memberships
      const groupIds = this.roleBindings.filter(binding => binding.bearer.bearerType === BearerType.Group).map(binding => binding.bearer.bearerId)
      const userIdToGroupIds: Map<string, string[]> = new Map<string, string[]>()
      for (const groupId of groupIds) {
        const indirect = (await RoleStore.getRoleBindingsGroup(groupId))?.filter(binding => binding.bearer.bearerType === BearerType.User).map(binding => binding.bearer.bearerId) || []
        for (const userId of indirect) {
          const groups = userIdToGroupIds.get(userId) || []
          groups.push(groupId)
          userIdToGroupIds.set(userId, groups)
          userIds.add(userId)
        }
      }

      // groupMembers
      const groupMembers: GroupMember[] = []
      for (const groupId of groupIds) {
        const groupBinding = this.roleBindings.find(binding => {
          return binding.bearer && binding.bearer.bearerId === groupId
        })
        const group = await GroupStore.getGroup({ id: groupId, platform: false })

        if (!group || !groupBinding) {
          continue
        }
        groupMembers.push({ group: group, role: groupBinding.role, binding: groupBinding, me: false })
      }

      this.groupMembers = groupMembers

      // retrieve users from backend
      await UserStore.getUsers(Array.from(userIds))
      const me = AuthStore.currentUserInfo?.id

      // Calc effective Role on Template
      const newMembers: Member[] = []
      for (const userId of userIds) {
        let role: Role = Role.Anonymous
        let source: Group | 'direct' | null = null
        let binding: RoleBinding | null = null
        if (direct.includes(userId)) {
          binding = (this.roleBindings.find(binding => binding.bearer.bearerId === userId)) ?? null
          role = binding?.role || Role.Anonymous
          source = 'direct'
        }
        if (userIdToGroupIds.has(userId)) {
          for (const groupId of userIdToGroupIds.get(userId)!) {
            const groupBinding = (this.roleBindings.find(binding => binding.bearer.bearerId === groupId)) ?? null
            const groupRole = groupBinding?.role ?? Role.Anonymous
            if (!role || templateRoles.indexOf(groupRole) > templateRoles.indexOf(role)) {
              binding = groupBinding
              role = groupRole
              source = (await GroupStore.getGroup({ id: groupId, platform: false }))
            }
          }
        }

        if (binding) {
          newMembers.push({ user: UserStore.users[userId], source, role, binding, me: UserStore.users[userId]?.id === me })
        } else {
          console.warn('Could NOT find binding?!?')
        }
      }
      this.members = newMembers
    }
  }

  public async updateRole (member: Member): Promise<void> {
    const newBinding = _.cloneDeep(member.binding)
    newBinding.role = member.role
    await RoleStore.addRoleBindingToTemplate({
      templateHash: this.template.hash,
      binding: newBinding
    })
    await this.onTemplatePropChange()
  }

  public async deleteMember (member: Member): Promise<void> {
    const confirmation = await this.$confirm(`Are you sure you want to revoke ${member.user.displayName}'s access to this template?`, { title: 'Revoke access?', icon: '' })
    if (!confirmation) return
    await RoleStore.removeRolebindingFromTemplate(member.binding)
    await this.onTemplatePropChange()
  }

  public async onInviteMemberClick (bindings: RoleBinding[]): Promise<void> {
    this.$emit('show-invite-member', bindings)
  }

  public async onInviteGroupClick (bindings: RoleBinding[]): Promise<void> {
    this.$emit('show-invite-group', bindings)
  }

  public mounted (): void {
    this.modalEvents.on('refresh-template-bindings', this.onTemplatePropChange)
  }
}
