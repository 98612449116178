import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  KeyElements,
  ModelTypes,
  SubmodelElement
} from '@aas-dashboard/i40-aas/models'
import { IConceptDescriptionStore } from '../../store/IConceptDescriptionStore'

@Component({
  components: { }
})

export default class BaseView<SubmodelElementType extends SubmodelElement> extends Vue {
  @Prop() readonly aasHash!: string
  @Prop() readonly submodelId!: string
  @Prop() readonly submodelElement!: SubmodelElementType
  @Prop() readonly showValues!: boolean
  @Prop() readonly showConceptDescriptions!: boolean
  @Prop() readonly conceptDescriptionStore!: IConceptDescriptionStore

  ModelTypes: unknown = ModelTypes
  KeyElements: unknown = KeyElements

  get modelType (): ModelTypes | null {
    return this.submodelElement?.modelType.name || null
  }
}
