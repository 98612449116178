





































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Asset, AssetAdministrationShell, Submodel } from '@aas-dashboard/i40-aas/models'

@Component({
  name: 'view-json-dialog'
})
export default class ViewJSONDialog extends Vue {
  @Prop() readonly value!: boolean
  @Prop() readonly data!: AssetAdministrationShell | Submodel | Asset
  @Prop() readonly revision!: string | undefined

  public get dialog (): boolean {
    return this.value
  }

  public set dialog (value: boolean) {
    this.$emit('input', value)
  }

  public get title (): string {
    let result = ''
    if (this.data) {
      if (this.data.modelType.name) {
        result += this.data.modelType.name + ' : '
      }
      if (this.data.idShort) {
        result += this.data.idShort
      }
    }
    return result
  }

  public async downloadJson (): Promise<void> {
    const fileURL = window.URL.createObjectURL(new Blob([JSON.stringify(this.data, null, 2)], { type: 'text/plain;charset=utf-8' }))
    const fileLink = document.createElement('a')

    const fileName = this.data.modelType.name + '_' + this.data.idShort + (this.revision ? `_${this.revision}` : '') + '.json'

    fileLink.href = fileURL
    fileLink.setAttribute('style', 'display: none')
    fileLink.setAttribute('download', fileName)
    document.body.appendChild(fileLink)

    fileLink.click()
  }
}
