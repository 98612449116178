








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Group } from '@aas-dashboard/repo-api/models'

@Component
export default class AddGroupModal extends Vue {
  @Prop() readonly dialog!: boolean

  public newGroup: Group = {
    id: '',
    name: ''
  }
}
