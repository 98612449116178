













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Group, ResourceType, Role, RoleBinding } from '@aas-dashboard/repo-api/models'
import RoleStore from '@/store/modules/RoleStore'
import DescriptorStore from '@/store/modules/DescriptorStore'
import { groupRoles } from '@/components/admin/models'
import { MyEventEmitter } from '@/event'
import { AASTemplateHash } from '@aas-dashboard/misc/types'
import Truncate from '@aas-dashboard/frontend-components/view/Truncate.vue'

interface TemplateEntry {
  hash: AASTemplateHash;
  idShort: string;
  description: string;
  revision: string;
  role: Role;
}

@Component({
  components: { Truncate }
})
export default class ViewGroupTemplatesModal extends Vue {
  @Prop() readonly dialog!: boolean
  @Prop() readonly group!: Group
  @Prop() readonly modalEvents!: MyEventEmitter

  Role: typeof Role = Role

  private templatesHeader = [
    { text: 'Short Id', value: 'idShort' },
    { text: 'Description', value: 'description' },
    { text: 'Last revision', value: 'revision' },
    { text: 'Role', value: 'role' },
    { text: '', value: 'controls', sortable: false, align: 'right' }
  ]

  public templatesEntries: TemplateEntry[] = []

  public templateRoleBindings: RoleBinding[] = []

  get roles (): Role[] {
    return groupRoles
  }

  @Watch('group')
  public async onGroupPropChange (): Promise<void> {
    if (this.group) {
      const roleBindings = await RoleStore.getRoleBindingsGroup(this.group.id) ?? []
      this.templateRoleBindings = roleBindings.filter((binding: RoleBinding) => binding.resource.resourceType === ResourceType.Template)
      this.templatesEntries = await Promise.all(this.templateRoleBindings.map(async binding => {
        const hash = binding.resource.resourceId
        const templateDescriptor = await DescriptorStore.getAASDescriptorByHash(hash)
        // REVIEW: how to do this null check nicer?
        if (!templateDescriptor?.description) {
          return { hash: '', idShort: '', description: '', revision: '', role: Role.Guest }
        }
        const revision = templateDescriptor.revisions[templateDescriptor.revisions.length - 1]
        return {
          hash: hash,
          idShort: templateDescriptor.idShort,
          description: templateDescriptor.description,
          revision: revision,
          role: binding.role
        }
      }))
    }
  }

  public mounted (): void {
    this.modalEvents.on('refresh-group-bindings', this.onGroupPropChange)
  }

  private openAASTemplate (value: TemplateEntry): void {
    this.$router.push({ name: 'aasTemplateView', params: { aasTemplateHash: value.hash } })
  }
}
