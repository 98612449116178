import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'

export enum AlertLvl {
  INFO = 0,
  WARNING = 1,
  ERROR = 3
}

export class Alert {
  message: string;
  top: boolean;
  level: AlertLvl;

  constructor (level: AlertLvl, message: string, top = true) {
    this.level = level
    this.message = message
    this.top = top
  }
}

export interface IAlertStore {
  queue: Array<Alert>;
}

@Module({ namespaced: true, store: store, name: 'alertstore', dynamic: true })
class AlertStore extends VuexModule implements IAlertStore {
  queue: Array<Alert> = []

  @Mutation
  public pushAlert (alert: Alert): void {
    this.queue.push(alert)
  }

  @Mutation
  public popAlert (): Alert | undefined {
    if (this.queue.length > 0) {
      return this.queue.shift()
    }
    return undefined
  }

  get peek (): Alert {
    return this.queue[0]
  }
}

export default getModule(AlertStore)
