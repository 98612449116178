



















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { DEFAULT_BACKEND_URL } from '@/constants'
import DescriptorStore from '@/store/modules/DescriptorStore'
import { identifierToStr } from '@aas-dashboard/misc/utils/i40-strings'
import AuthStore from '@/store/modules/AuthStore'
import { AASTemplateHash } from '@aas-dashboard/misc/types'
import InviteMemberModal from '@/components/template/management/InviteMemberModal.vue'
import InviteGroupModal from '@/components/template/management/InviteGroupModal.vue'
import EditTemplatePermissionsModal from '@/components/template/management/EditTemplatePermissionsModal.vue'
import {
  AASTemplateDescriptor,
  Role,
  RoleBinding
} from '@aas-dashboard/repo-api'
import { MyEventEmitter } from '@/event'
import RoleStore from '@/store/modules/RoleStore'
import AASTemplateStore from '@/store/modules/AASTemplateStore'
import { Timer } from '@aas-dashboard/misc/utils/timer'
import Truncate from '@aas-dashboard/frontend-components/view/Truncate.vue'

type TemplateDescriptorEntry = {
  hash: AASTemplateHash;
  idShort: string;
  identifierFlat: string;
  description: string;
  authors: string;
  revision: string;
  myRoleBinding: RoleBinding | null;
  canEdit: boolean;
}

@Component({
  components: { EditTemplatePermissionsModal, InviteMemberModal, InviteGroupModal, Truncate }
})
export default class MyTemplateList extends Vue {
  AuthStore: typeof AuthStore = AuthStore
  AASTemplateStore: typeof AASTemplateStore = AASTemplateStore

  private search = ''
  private sortBy = 'idShort'
  private sortDesc = false

  public modalEvents = new MyEventEmitter()

  public showEditPermissions = false
  public templateToEdit: AASTemplateDescriptor | null = null
  public templateToEditRoleBinding: RoleBinding | null = null
  public showInviteMember = false
  public showInviteGroup = false
  public templateToEditRoleBindings: RoleBinding[] | null = null

  private refreshProcessingStateTimer: Timer = new Timer(1000, this.refreshProcessingStatesWrapper, true)
  private repeatCnt = 0

  private headers = [
    // { text: 'Icon', value: 'thumbnailUrl', sortable: false, filterable: false },
    { text: 'Short Id', value: 'idShort' },
    { text: 'Identifier', value: 'identifierFlat' },
    { text: 'Description', value: 'description' },
    { text: 'Authors', value: 'authors' },
    { text: 'Revision', value: 'revision' },
    { text: '', value: 'controls', sortable: false, align: 'right' }
  ]

  private processingStateHeaders = [
    { text: 'Upload data', value: 'uploadedOn' },
    { text: 'Filename', value: 'fileName' },
    { text: 'Revision', value: 'revision' },
    { text: 'State', value: 'status' },
    { text: 'Message', value: 'statusMsg' }
  ]

  get templateDescriptors (): TemplateDescriptorEntry[] {
    const result: TemplateDescriptorEntry[] = []

    for (const desc of Object.values(DescriptorStore.aasDescriptors)) {
      desc.revisions.forEach(revision => {
        result.push({
          hash: desc.hash,
          idShort: desc.idShort,
          identifierFlat: identifierToStr(desc.identifier),
          description: desc.description ?? '',
          authors: desc.authors?.join(',') ?? '<UNKNOWN_USER>',
          revision: revision,
          myRoleBinding: RoleStore.myTemplateRoleBindings[desc.hash],
          canEdit: RoleStore.myTemplateRoleBindings[desc.hash]?.role === Role.Editor
        })
      })
    }
    return result
  }

  private openAASTemplate (value: TemplateDescriptorEntry): void {
    this.$router.push({ name: 'aasTemplateView', params: { aasTemplateHash: value.hash, revision: value.revision } })
  }

  public onEditClick (value: TemplateDescriptorEntry): void {
    this.$router.push({ name: 'uploadAASTemplate', params: { aasTemplateHash: value.hash } })
  }

  public async deleteTemplate (templateDescriptor: TemplateDescriptorEntry): Promise<void> {
    if (!templateDescriptor) return
    // TODO: Create custom dialog to show the template's fields
    const confirmation = await this.$confirm(
      'Are you sure you want to delete this template:<br/>' +
        `${templateDescriptor.idShort}/${templateDescriptor.revision}<br/><br/>` +
        'Warning: This action cannot be undone without the corresponding .aasx file.',
      { title: 'Delete template?', icon: '' }
    )
    if (!confirmation) return
    await AASTemplateStore.deleteTemplate({
      templateHash: templateDescriptor.hash,
      revision: templateDescriptor.revision
    })
  }

  public onEditTemplatePermissionsClick (value: TemplateDescriptorEntry): void {
    if (!value) return
    this.templateToEdit = DescriptorStore.aasDescriptors[value.hash]
    this.templateToEditRoleBinding = value.myRoleBinding
    this.showEditPermissions = true
  }

  public onEditTemplatePermissionsClosed (): void {
    this.showEditPermissions = false
    this.templateToEdit = null
    this.templateToEditRoleBinding = null
    this.modalEvents.emit('refresh-template-bindings')
  }

  public onShowInviteMember (currentRoleBindings: RoleBinding[]): void {
    this.showInviteMember = true
    this.templateToEditRoleBindings = currentRoleBindings
  }

  public onInviteMemberClosed (): void {
    this.showInviteMember = false
    this.templateToEditRoleBindings = null
    this.modalEvents.emit('refresh-template-bindings')
  }

  public onShowInviteGroup (currentRoleBindings: RoleBinding[]): void {
    this.showInviteGroup = true
    this.templateToEditRoleBindings = currentRoleBindings
  }

  public onInviteGroupClosed (): void {
    this.showInviteGroup = false
    this.templateToEditRoleBindings = null
    this.modalEvents.emit('refresh-template-bindings')
  }

  public async onRefreshTemplateBindings (): Promise<void> {
    await RoleStore.updateMyState()
  }

  @Watch('AuthStore.isUserSignedIn')
  public async refreshProcessingStatesStart (): Promise<void> {
    // poll the TemplateProcessingState for X seconds
    this.repeatCnt = 30
    if (!this.refreshProcessingStateTimer.isRunning()) this.refreshProcessingStateTimer.start()
  }

  public async refreshProcessingStates (): Promise<void> {
    if (AuthStore.isUserSignedIn) {
      await AASTemplateStore.bulkUpdateProcessingStates(AuthStore.currentUserInfo?.id)
    }
  }

  public async refreshProcessingStatesWrapper (): Promise<void> {
    await this.refreshProcessingStates()

    this.repeatCnt -= 1
    if (this.repeatCnt <= 0) {
      this.refreshProcessingStateTimer.stop()
    }
  }

  public async mounted (): Promise<void> {
    await this.onRefreshTemplateBindings()
    this.modalEvents.on('refresh-template-bindings', this.onRefreshTemplateBindings)

    await this.refreshProcessingStatesStart()
  }
}
