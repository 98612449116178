import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import {
  AssetAdministrationShell,
  Identifier, Reference,
  Submodel
} from '@aas-dashboard/i40-aas/models'
import { Vue } from 'vue-property-decorator'
import store from '@/store'
import wsUpdateClient from './ws-update-client'
import { getLogger } from '@aas-dashboard/misc/logger'
import { DEFAULT_BACKEND_OPENAPI_URL } from '@/constants'
import { Configuration } from '@aas-dashboard/repo-api/configuration'
import _ from 'lodash'
import { DescriptorsApi, TemplateProcessingState, TemplatesApi } from '@aas-dashboard/repo-api'
import { AASTemplateHash } from '@aas-dashboard/misc/types'
import {
  TOPIC_AAS_TEMPLATES
} from '@aas-dashboard/asyncapi/services/constants'
import { TemplateMetadata, TemplateMetadataUpdate } from '@aas-dashboard/repo-api/models'
import { BASE_PATH } from '@aas-dashboard/repo-api/base'
import { axiosInstance } from '@/axios'
import { ResolvedReference } from '@aas-dashboard/api/models'

const LOG = getLogger('aas-template-store')

export type IdShort = string

export interface IAASTemplateStore {
  aases: { [key: string]: AssetAdministrationShell };
  aasToSubmodels: { [key: string]: string[] };
  submodels: { [key: string]: Submodel };
  myProcessingStates: TemplateProcessingState[];
}

@Module({ namespaced: true, store: store, name: 'aastemplatestore', dynamic: true })
class AASTemplateStore extends VuexModule implements IAASTemplateStore {
  //
  // NOTE: Hashes are used for most lookup functions where '[key: string]' is used.
  //
  public descriptorsApiService: DescriptorsApi | null = null
  public templatesApiService: TemplatesApi | null = null

  public aases: { [key: string]: AssetAdministrationShell } = {}

  public aasToSubmodels: { [key: string]: string[] } = {}

  public submodels: { [key: string]: Submodel } = {} // key = aasHash/smIdShort

  public myProcessingStates: TemplateProcessingState[] = []

  @Mutation
  private setupAPIService (): void {
    this.descriptorsApiService = new DescriptorsApi(new Configuration({
      basePath: DEFAULT_BACKEND_OPENAPI_URL,
      baseOptions: {
        withCredentials: true
      }
    }), BASE_PATH, axiosInstance)
    this.templatesApiService = new TemplatesApi(new Configuration({
      basePath: DEFAULT_BACKEND_OPENAPI_URL,
      baseOptions: {
        withCredentials: true
      }
    }), BASE_PATH, axiosInstance)
  }

  @Mutation
  public addAAS ({ templateHash, aas }: { templateHash: AASTemplateHash; aas: AssetAdministrationShell }): void {
    Vue.set(this.aases, templateHash, aas)
  }

  @Mutation
  public upsertSubmodel ({ templateHash, submodel }: { templateHash: AASTemplateHash; submodel: Submodel }): void {
    const submodels = this.aasToSubmodels[templateHash] || []
    if (!submodels.includes(submodel.idShort)) {
      submodels.push(submodel.idShort)
      Vue.set(this.aasToSubmodels, templateHash, submodels)
    }
    Vue.set(this.submodels, `${templateHash}/${submodel.idShort}`, submodel)
  }

  @Mutation
  public removeSubmodel ({ templateHash, submodel }: { templateHash: AASTemplateHash; submodel: { idShort: string} }): void {
    if (this.aasToSubmodels[templateHash]?.includes(submodel.idShort)) {
      delete this.submodels[`${templateHash}/${submodel.idShort}`]
      Vue.set(this.aasToSubmodels, templateHash, this.aasToSubmodels[templateHash].filter(e => e !== submodel.idShort))
    }
  }

  @Mutation
  public updateProcessingStates (newStates: TemplateProcessingState[]): void {
    this.myProcessingStates = newStates
  }

  get getSubmodel () {
    return (templateHash: AASTemplateHash, submodelIdShort: string): Submodel | null => {
      return this.submodels[`${templateHash}/${submodelIdShort}`] || null
    }
  }

  get getSubmodelsIdShort () {
    return (templateHash: AASTemplateHash): string[] => {
      return this.aasToSubmodels[templateHash] || []
    }
  }

  get getSubmodelIdShortByAASTemplateHashAndIdentifier () {
    return (templateHash: AASTemplateHash, identifier: Identifier): string | undefined => {
      const submodelIds: string[] = this.aasToSubmodels[templateHash]
      if (submodelIds) {
        const submodels: Submodel[] = submodelIds.map((idShort: string) => this.submodels[`${templateHash}/${idShort}`])
        return _.find(submodels, (submodel: Submodel) => _.isEqual(submodel.identification, identifier))?.idShort
      }
      return undefined
    }
  }

  // Consider using the getSubmodelIdShortByAASHashAndIdentifier, because it's more efficient
  get getSubmodelByIdentifier () {
    return (identifier: Identifier): [AASTemplateHash, Submodel] | null => {
      const id = _.findKey(this.submodels, (submodel: Submodel) => _.isEqual(submodel.identification, identifier))
      if (id) {
        const hash = (id.split('/'))[0]
        return [hash, this.submodels[id]]
      }
      return null
    }
  }

  get isLoaded () {
    return (templateHash: AASTemplateHash): boolean => {
      return this.aasToSubmodels[templateHash] !== undefined
    }
  }

  @Action({ rawError: true })
  private async updateAAS (templateHash: string) {
    if (this.templatesApiService === null) this.setupAPIService()

    let aas: AssetAdministrationShell | undefined
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      aas = (await this.templatesApiService!.retrieveTemplate(templateHash))?.data
      // TODO: remove aas from cache
      if (!aas) return
      this.addAAS({ templateHash: templateHash, aas })
    } catch (e) {
      console.warn('Failed to retrieve AAS', templateHash)
      return
    }

    await this.updateSubmodels(templateHash)
  }

  @Action({ rawError: true })
  public async updateSubmodels (templateHash: string) {
    let submodels: Submodel[] | undefined
    if (this.templatesApiService === null) this.setupAPIService()
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      submodels = (await this.templatesApiService!.retrieveTemplateSubmodels(templateHash))?.data
    } catch (e) {
      console.warn('Failed to retrieve submodels', templateHash)
    }
    if (!submodels) {
      return
    }
    LOG.debug('Updating submodels for AAS', templateHash, submodels)
    for (const submodel of submodels) {
      LOG.debug('Updating submodel', templateHash, submodel)
      this.upsertSubmodel({ templateHash, submodel })
    }
  }

  @Action({ rawError: true })
  public async loadAAS (aasHash: string) {
    if (this.isLoaded(aasHash)) return
    await this.updateAAS(aasHash)

    await wsUpdateClient.trackAASTemplates({ hash: aasHash })
    wsUpdateClient.on(TOPIC_AAS_TEMPLATES, async () => {
      // FIXME: Only register .on() once
      await this.updateAAS(aasHash)
    })
  }

  @Action({ rawError: true })
  public async submitTemplateUpdate ({ metadata, hash, revision, file }: {metadata?: TemplateMetadataUpdate, hash?: AASTemplateHash, revision?: string, file?: File}) {
    if (this.templatesApiService === null) this.setupAPIService()
    if (!hash) throw new Error('Missing hash')
    if (!file && !metadata) throw new Error('Missing file and metadata')

    if (file) {
      if (!revision) throw new Error('Missing revision')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await this.templatesApiService!.updateTemplateFile(hash, revision, file)
    }
    if (metadata) {
      await this.templatesApiService!.updateTemplateMetadata(hash, metadata)
    }
  }

  @Action({ rawError: true })
  public async submitNewTemplate ({ metadata, revision, file }: {metadata: TemplateMetadata, revision?: string, file?: File}) {
    if (this.templatesApiService === null) this.setupAPIService()
    if (!file) throw new Error('Missing file')
    if (!revision) throw new Error('Missing file or revision')

    await this.templatesApiService!.uploadNewTemplateAndMetadata(metadata, revision, file)
  }

  @Action({ rawError: true })
  public async downloadTemplateAASX (args: {hash: AASTemplateHash, revision?: string}) {
    const { hash, revision } = args
    const resp = (await this.templatesApiService?.retrieveTemplate(hash, revision, {
      headers: { Accept: 'application/asset-administration-shell-package' },
      responseType: 'arraybuffer'
    }))
    return resp?.data ?? null
  }

  @Action({ rawError: true })
  public async getProcessingStates (userId?: string, limit?: number): Promise<TemplateProcessingState[]> {
    if (this.templatesApiService === null) this.setupAPIService()

    let result: TemplateProcessingState[] = []
    try {
      result = (await this.templatesApiService?.retrieveAllProcessingStates(userId, limit || 10))?.data ?? []
    } catch (e) {
      console.error('Failed to retrieve processing states', e)
    }

    return result
  }

  @Action({ rawError: true })
  public async bulkUpdateProcessingStates (userId?: string): Promise<void> {
    const result = await this.getProcessingStates(userId)

    if (result) {
      this.updateProcessingStates(result)
    }
  }

  @Action({ rawError: true })
  public async deleteTemplate ({ templateHash, revision }: { templateHash: AASTemplateHash, revision: string }): Promise<void> {
    if (this.templatesApiService === null) this.setupAPIService()

    await this.templatesApiService?.deleteTemplate(templateHash, revision)
    delete this.aases[templateHash]
  }

  @Action({ rawError: true })
  public async resolveReference (reference: Reference): Promise<ResolvedReference | null> {
    const response: ResolvedReference[] = await this.resolveReferences([reference])
    return response.length === 1 && response[0] && response[0].path && response[0].path.length > 0 ? response[0] : null
  }

  @Action({ rawError: true })
  public async resolveReferences (references: Reference[]): Promise<ResolvedReference[]> {
    if (this.templatesApiService === null) this.setupAPIService()

    // TODO: Cache resolved reference during a TTL?
    const response = await this.templatesApiService?.resolveReferences(references)
    return response?.data || []
  }
}

export default getModule(AASTemplateStore)
