















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Operation } from '@aas-dashboard/i40-aas/models'
import OperationInputOutputList from './OperationInputOutputList.vue'
import SemanticTooltip from './SemanticTooltip.vue'
import { isOperation } from '@aas-dashboard/misc/guards/i40-models'
import BaseView from './view/BaseView'

@Component({
  components: { SemanticTooltip, BaseView, OperationInputOutputList }
})
export default class OperationList extends BaseView<Operation> {
  @Prop() readonly sePath!: string

  get operation (): Operation | null {
    if (isOperation(this.submodelElement)) {
      return this.submodelElement
    }
    return null
  }

  inputHeader = 'Input variables:'
  noInputMessage = 'No input variables'
  outputHeader = 'Output variables:'
  noOutputMessage = 'No output variables'
  inOutHeader = 'InOut variables:'
  noInOutMessage = 'No InOut variables'

  private panels = []
}
