const baseUrl = new URL(window.location.origin)

const baseOpenAPIUrl = new URL(window.location.origin + '/aas-repository/v1')

const baseOpenAPIAuthUrl = new URL(window.location.origin + '/auth/v1')

const wsBaseUrl = new URL(window.location.origin + '/ws')
switch (wsBaseUrl.protocol) {
  case 'https:':
    wsBaseUrl.protocol = 'wss'
    break
  case 'http:':
  default:
    wsBaseUrl.protocol = 'ws'
}

export const DEFAULT_BACKEND_URL = baseUrl.href
export const DEFAULT_BACKEND_OPENAPI_URL = baseOpenAPIUrl.href
export const DEFAULT_BACKEND_OPENAPI_AUTH_URL = baseOpenAPIAuthUrl.href
export const DEFAULT_BACKEND_WS_URL = wsBaseUrl.href

export const AAS_TEMPLATE_REPO_TITLE = 'AAS Template Repository'
