







import { Component } from 'vue-property-decorator'
import BaseView from './BaseView'
import ReferenceElementView from './ReferenceElementView.vue'
import { Reference, RelationshipElement } from '@aas-dashboard/i40-aas/models'
import ReferenceView from './ReferenceView.vue'
import { isRelationshipElement } from '@aas-dashboard/misc/guards/i40-models'

@Component({
  components: { ReferenceElementView, ReferenceView }
})

export default class RelationshipElementView extends BaseView<RelationshipElement> {
  get getFirst (): Reference | null {
    if (isRelationshipElement(this.submodelElement)) {
      return this.submodelElement.first
    }
    return null
  }

  get getSecond (): Reference | null {
    if (isRelationshipElement(this.submodelElement)) {
      return this.submodelElement.second
    }
    return null
  }
}
