







import { Component, Inject } from 'vue-property-decorator'
import BaseView from './BaseView'
import { FileElement } from '@aas-dashboard/i40-aas/models'
import { isFile, isExtendedSubmodelElement } from '@aas-dashboard/misc/guards/i40-models'
import { AASHash } from '@aas-dashboard/misc/types'
import Truncate from '../../view/Truncate.vue'

@Component({ components: { Truncate } })
export default class FileView extends BaseView<FileElement> {
  @Inject() readonly binaryUrlTemplate!: (aasHash: AASHash, submodelId: string, sePath: string) => string

  get fileUrl (): string | null {
    if (isExtendedSubmodelElement(this.submodelElement)) {
      const url = this.binaryUrlTemplate(this.aasHash, this.submodelId, this.submodelElement.sepath)
      return url ? `${url}?returnType=FILE` : null
    } else {
      return null
    }
  }

  get fileStr (): string {
    if (isFile(this.submodelElement)) {
      return this.submodelElement.value
    } else {
      return 'Error: Not a File object'
    }
  }

  public gotoUrl (url: string): void {
    window.open(url, '_blank')
  }
}
