var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Asset Administration Shell Templates")]),_c('v-card-text',[_c('p',[_vm._v(" Here all the available templates are visible with short descriptions. Click on a row to open the template. ")]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.templateDescriptors,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.openAASTemplate},scopedSlots:_vm._u([{key:"item.idShort",fn:function(ref){
var item = ref.item;
return [_c('truncate',{attrs:{"maxLines":3,"max-width":"200px"}},[_vm._v(" "+_vm._s(item.idShort)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('truncate',{attrs:{"maxLines":3,"minWidth":"150px"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.identifierFlat",fn:function(ref){
var item = ref.item;
return [_c('truncate',{attrs:{"maxLines":3,"max-width":"150px"}},[_vm._v(" "+_vm._s(item.identifierFlat)+" ")])]}},{key:"item.authors",fn:function(ref){
var item = ref.item;
return [_c('truncate',{attrs:{"maxLines":3,"max-width":"150px"}},[_vm._v(" "+_vm._s(item.authors)+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }