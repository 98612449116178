






















import { Component, Vue } from 'vue-property-decorator'
import AuthStore from '@/store/modules/AuthStore'
import { DEFAULT_BACKEND_OPENAPI_AUTH_URL } from '../../constants'

@Component
export default class GoogleSigninButton extends Vue {
  get redirectUrl (): string {
    return `${DEFAULT_BACKEND_OPENAPI_AUTH_URL}/login?origin=${window.location.href}`
  }

  get userName (): string | undefined {
    return AuthStore.currentUserDisplayname
  }

  public redirectToAuth () {
    document.location.href = this.redirectUrl
  }

  public signOut () {
    AuthStore.signOut()
  }
}
