import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Configuration } from '@aas-dashboard/repo-api/configuration'
import store from '@/store'
import { DEFAULT_BACKEND_OPENAPI_URL } from '@/constants'
import { AccessApi } from '@aas-dashboard/repo-api'
import {
  Group, InviteInfo
} from '@aas-dashboard/repo-api/models'
import { Vue } from 'vue-property-decorator'
import { axiosInstance } from '@/axios'
import { BASE_PATH } from '@aas-dashboard/repo-api/base'
import AuthStore from '@/store/modules/AuthStore'

export interface IGroupStore {
  groups: { [key: string]: Group & { member: boolean } };
}

@Module({ namespaced: true, store: store, name: 'groupstore', dynamic: true })
class GroupStore extends VuexModule implements IGroupStore {
  public groups: { [key: string]: Group & { member: boolean }} = {}

  private accessApiService: AccessApi | null = null

  @Mutation
  public setupAPIService (): void {
    this.accessApiService = new AccessApi(new Configuration({
      basePath: DEFAULT_BACKEND_OPENAPI_URL,
      baseOptions: {
        withCredentials: true
      }
    }), BASE_PATH, axiosInstance)
  }

  @Mutation
  private addGroup ({ group, platform = false } :{ group: Group, platform: boolean }) {
    const member = (this.groups[group.id]?.member ?? false) || !platform // note: sometimes the platform query goes first
    Vue.set(this.groups, group.id, { ...group, member })
  }

  @Mutation
  private removeGroup ({ group } :{ group: Group }) {
    Vue.delete(this.groups, group.id)
  }

  @Action({ rawError: true })
  public async getGroup ({ id, platform = false }: { id: string, platform: boolean }): Promise<Group | null> {
    if (!this.groups[id]) {
      if (this.accessApiService === null) this.setupAPIService()

      let group: Group | undefined
      try {
        group = (await this.accessApiService?.retrieveGroup(id))?.data
      } catch (e) {
        console.warn('Failed to retrieve Group', id)
      }
      if (!group) return null

      this.addGroup({ group, platform })
    }
    return this.groups[id]
  }

  @Action({ rawError: true })
  public async getGroups (platform = false): Promise<Group[] | null> {
    if (this.accessApiService === null) this.setupAPIService()

    const userId: string | undefined = platform ? undefined : AuthStore.currentUserInfo?.id
    let groups: Group[] | undefined
    try {
      groups = (await this.accessApiService?.retrieveGroups(userId))?.data
    } catch (e) {
      console.warn('Failed to retrieve groups')
    }
    if (!groups) return null

    for (const group of groups) {
      this.addGroup({ group, platform })
    }

    const newGroupIds = groups.map(group => group.id)
    for (const groupId of Object.keys(this.groups)) {
      if (!newGroupIds.includes(groupId)) {
        this.removeGroup({ group: this.groups[groupId] })
      }
    }

    return Object.values(this.groups)
  }

  @Action({ rawError: true })
  public async createGroup ({ group, platform = false }: { group: Group, platform: boolean }): Promise<void> {
    if (this.accessApiService === null) this.setupAPIService()

    await this.accessApiService?.createGroup(group)

    await this.getGroups(platform)
  }

  @Action({ rawError: true })
  public async updateGroup ({ group, platform = false }: { group: Group, platform: boolean }): Promise<void> {
    if (this.accessApiService === null) this.setupAPIService()

    await this.accessApiService?.updateGroup(group.id, group)

    await this.getGroups(platform)
  }

  @Action({ rawError: true })
  public async deleteGroup ({ group, platform = false }: { group: Group, platform: boolean }): Promise<void> {
    if (this.accessApiService === null) this.setupAPIService()

    await this.accessApiService?.deleteGroup(group.id)
    await this.getGroups(platform)
  }

  @Action({ rawError: true })
  public async listGroupInviteLinks (groupId: string): Promise<InviteInfo[]> {
    if (this.accessApiService === null) this.setupAPIService()
    return (await this.accessApiService?.listInviteTokens(groupId))?.data || []
  }

  @Action({ rawError: true })
  public async generateGroupInviteToken ({ group, info }: { group: Group, info: InviteInfo }): Promise<InviteInfo | undefined> {
    if (this.accessApiService === null) this.setupAPIService()
    console.log('Creating token with info: ' + info)
    return (await this.accessApiService?.generateInviteToken(group.id, info))?.data
  }

  @Action({ rawError: true })
  public async deleteGroupInviteToken ({ groupId, inviteToken }: { groupId: string, inviteToken: string }): Promise<void> {
    if (this.accessApiService === null) this.setupAPIService()
    await this.accessApiService?.deleteInviteToken(groupId, inviteToken)
  }
}

export default getModule(GroupStore)
