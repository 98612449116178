
export class Timer {
  private _timeout: ReturnType<typeof setTimeout> | null = null
  private _stop = false

  constructor(private timeout_ms: number, private callback: () => void, private resetImmediately = true) { }

  public start (): void {
    this.reset()
  }

  public reset (): void {
    this._stop = false
    this._timeout = setTimeout(() => {
      this.callback()
      if (this.resetImmediately && !this._stop) {
        this.reset()
      }
    }, this.timeout_ms)
  }

  public stop (): void {
    this._stop = true
    if (this._timeout) {
      clearTimeout(this._timeout)
      this._timeout = null
    }
  }

  public isRunning (): boolean {
    return this._timeout !== null && !this._stop
  }
}
