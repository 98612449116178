var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edit Template Permissions")]),_c('v-card-text',[_c('p',[_vm._v("Only the users below can see and access this template. You can invite new users and change user permissions. There are three types of user permissions:   "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('i',[_vm._v("Guest")]),_c('sup',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-1","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle")])],1)])]}}])},[_c('span',[_vm._v("Can see the different models, cannot edit the already existing models, but can create and edit their own")])]),_vm._v("   "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('i',[_vm._v("Member")]),_c('sup',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-1","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle")])],1)])]}}])},[_c('span',[_vm._v("Can see the different models, cannot edit the already existing models, but can create and edit their own")])]),_vm._v("   "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('i',[_vm._v("Editor")]),_c('sup',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-1","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle")])],1)])]}}])},[_c('span',[_vm._v("Can see, create and edit any of the available models")])])],1),(_vm.template)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('span',{staticClass:"body-1 text--primary"},[_vm._v(" Template: "),_c('i',[_vm._v(_vm._s(_vm.template.idShort))])])]),_c('v-row',[_c('v-col',[_c('span',{staticClass:"body-1 text--primary"},[_vm._v(" Groups with access: ")])]),_c('v-col',{attrs:{"md":"4"}},[_c('v-btn',{attrs:{"disabled":!!_vm.myRoleBinding && _vm.myRoleBinding.role !== _vm.Role.Editor},on:{"click":function($event){$event.stopPropagation();return _vm.onInviteGroupClick(_vm.roleBindings)}}},[_vm._v(" Invite group ")])],1)],1),_c('v-row',[_c('v-data-table',{attrs:{"headers":_vm.groupMembersHeader,"items":_vm.groupMembers,"dense":""},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.roles,"disabled":!!_vm.myRoleBinding && _vm.myRoleBinding.role !== _vm.Role.Editor,"label":"Role","required":""},on:{"change":function($event){return _vm.updateRole(item)}},model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}})]}},{key:"item.me",fn:function(ref){
var item = ref.item;
return [(item.me)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-star")]):_vm._e()]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.source === 'direct' ? item.source : item.source.name)+" ")]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","small":"","disabled":!!_vm.myRoleBinding && _vm.myRoleBinding.role !== _vm.Role.Editor},on:{"click":function($event){$event.stopPropagation();return _vm.deleteMember(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,1963621429)})],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"body-1 text--primary"},[_vm._v(" Members with access: ")])]),_c('v-col',{attrs:{"md":"4"}},[_c('v-btn',{attrs:{"disabled":!!_vm.myRoleBinding && _vm.myRoleBinding.role !== _vm.Role.Editor},on:{"click":function($event){$event.stopPropagation();return _vm.onInviteMemberClick(_vm.roleBindings)}}},[_vm._v(" Invite member ")])],1)],1),_c('v-row',[_c('v-data-table',{attrs:{"headers":_vm.membersHeader,"items":_vm.members,"dense":""},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.roles,"disabled":!!_vm.myRoleBinding && _vm.myRoleBinding.role !== _vm.Role.Editor || item.source !== 'direct',"label":"Role","required":""},on:{"change":function($event){return _vm.updateRole(item)}},model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}})]}},{key:"item.me",fn:function(ref){
var item = ref.item;
return [(item.me)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-star")]):_vm._e()]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.source === 'direct' ? item.source : item.source.name)+" ")]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [(item.source === 'direct')?_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","small":"","disabled":!!_vm.myRoleBinding && _vm.myRoleBinding.role !== _vm.Role.Editor},on:{"click":function($event){$event.stopPropagation();return _vm.deleteMember(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,false,4208414006)})],1),_c('v-row',[_c('p',[_c('i',[_vm._v("Note:")]),_vm._v(" You can also change your own role, however, be aware that there needs to be at least one user that is specified as "),_c('i',[_vm._v("Editor")]),_vm._v(".")])])],1):_c('div',[_vm._v(" No template selected ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edit-template-permissions-closed', undefined)}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }