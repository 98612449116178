




















































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AASTemplateDescriptor, TemplateMetadata, TemplateMetadataUpdate } from '@aas-dashboard/repo-api/models'
import AASTemplateStore from '@/store/modules/AASTemplateStore'
import AlertStore, { Alert, AlertLvl } from '@/store/modules/AlertStore'
import AuthStore from '@/store/modules/AuthStore'
import { AASTemplateHash } from '@aas-dashboard/misc/types'
import DescriptorStore from '@/store/modules/DescriptorStore'
import MetadataPanel from '@/components/template/aas/MetadataPanel.vue'
import { identifierToStr } from '@aas-dashboard/misc/utils/i40-strings'
import { AxiosError } from 'axios'

@Component({
  components: { MetadataPanel }
})
export default class UploadAASTemplate extends Vue {
  @Prop() aasTemplateHash: AASTemplateHash | undefined

  AuthStore: typeof AuthStore = AuthStore

  public identifier: string | null = null

  public fileValid = false
  public metadataValid = false

  public fileSubmitted = false
  public metadataSubmitted = false

  public newAuthors = ''
  public newDescription = ''
  public newRevision = ''
  public newFile?: File | null = null

  public show = false

  get currentDescriptor (): AASTemplateDescriptor | null {
    return this.aasTemplateHash ? DescriptorStore.getAASDescriptorByHash(this.aasTemplateHash) : null
  }

  public notEmpty (value?: string): true | string {
    if (!value) {
      return 'Must not be empty'
    }
    return true
  }

  public aasxExtension (value?: Partial<File>): true | string {
    if (!value?.name?.endsWith('.aasx')) {
      return 'Invalid extension'
    }
    return true
  }

  public mustBeUnique (value?: string): true | string {
    if (value && this.currentDescriptor && this.currentDescriptor.revisions?.includes(value)) {
      return 'Please specify a new revision'
    }
    return true
  }

  public async submitTemplate (submit: boolean): Promise<void> {
    if (!submit || !this.fileValid) return

    try {
      const file = this.newFile
      if (!file) throw new Error('Missing file')

      if (this.aasTemplateHash) {
        await AASTemplateStore.submitTemplateUpdate({ hash: this.aasTemplateHash, revision: this.newRevision, file })
      } else {
        const metadata: TemplateMetadata = {
          authors: [this.newAuthors],
          revisions: [this.newRevision],
          description: this.newDescription,
          createdOn: new Date().toISOString(),
          labelIds: []
        }
        await AASTemplateStore.submitNewTemplate({ revision: this.newRevision, file, metadata })
      }
      this.fileSubmitted = true
    } catch (err) {
      console.error(err)
      if ((err as AxiosError)?.response?.status === 413) {
        AlertStore.pushAlert(new Alert(AlertLvl.ERROR, 'Error while uploading the selected file: The file is too large.'))
      } else {
        AlertStore.pushAlert(new Alert(AlertLvl.ERROR, `Error while uploading the selected file: ${(err as Error)?.message}`))
      }
    }
  }

  public async submitMetadataUpdate (submit: boolean): Promise<void> {
    if (!submit || !this.metadataValid) return

    try {
      if (!this.aasTemplateHash) throw new Error('Missing template hash')
      const metadata: TemplateMetadataUpdate = {
        authors: [this.newAuthors],
        description: this.newDescription
      }
      await AASTemplateStore.submitTemplateUpdate({ hash: this.aasTemplateHash, metadata })
      this.metadataSubmitted = true
    } catch (err) {
      AlertStore.pushAlert(new Alert(AlertLvl.ERROR, `Error while uploading the metadata: ${(err as Error)?.message}`))
      console.error(err)
    }
  }

  public clear (): void {
    // FIXME: Changing prop directly warning
    this.aasTemplateHash = undefined
    this.newAuthors = ''
    this.newDescription = ''
    this.newRevision = ''
    this.newFile = undefined
    if (this.$refs.form) {
      (this.$refs.form as HTMLFormElement).reset()
    }
  }

  @Watch('aasTemplateHash')
  public async loadDescriptor (): Promise<void> {
    if (this.aasTemplateHash) {
      const storedDescriptor = DescriptorStore.getAASDescriptorByHash(this.aasTemplateHash)
      if (!storedDescriptor) {
        // TODO: the reason we need this seems to be due to the Store being initialized after this function has already been invoked
        // This only happens when navigating back using the browser's history
        await DescriptorStore.updateAASDescriptorByHash(this.aasTemplateHash)
      }
      await this.loadValues()
    }
  }

  @Watch('currentDescriptor')
  public async loadValues (): Promise<void> {
    if (this.currentDescriptor) {
      this.newDescription = this.currentDescriptor.description ?? ''
      this.newRevision = this.currentDescriptor.revisions && this.currentDescriptor.revisions.length > 0 ? this.currentDescriptor.revisions[this.currentDescriptor.revisions.length - 1] : '1'
      this.identifier = identifierToStr(this.currentDescriptor.identifier) ?? ''
      this.newAuthors = this.currentDescriptor.authors?.join(', ')
    }
  }

  public async mounted (): Promise<void> {
    await this.loadDescriptor()
  }
}
