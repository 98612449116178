




import { Component } from 'vue-property-decorator'
import BaseView from './BaseView'
import { Range } from '@aas-dashboard/i40-aas/models'
import { isRange } from '@aas-dashboard/misc/guards/i40-models'
import Truncate from '../../view/Truncate.vue'

@Component({ components: { Truncate } })
export default class RangeView extends BaseView<Range> {
  get rangeStr (): string {
    if (isRange(this.submodelElement)) {
      return this.submodelElement.valueType + ' -' + (this.submodelElement.min !== undefined ? ' min: ' + this.submodelElement.min : '') + (this.submodelElement.max !== undefined ? ' max: ' + this.submodelElement.max : '')
    } else {
      return 'Error: Not a Range object'
    }
  }
}
