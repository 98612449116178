




import { Component, Vue } from 'vue-property-decorator'
import AuthStore from '@/store/modules/AuthStore'

const KEEPALIVE_INTERVAL_MS = 1000 * 4 * 60

@Component
export default class SessionKeepalive extends Vue {
  private timer?: number

  public mounted (): void {
    this.timer = window.setInterval(() => {
      if (AuthStore.isUserSignedIn) {
        AuthStore.updateUserState()
      }
    }, KEEPALIVE_INTERVAL_MS)
  }

  public unmounted (): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
