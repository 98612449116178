import WebSocket from 'isomorphic-ws'
import { Configuration } from './configuration'
import { EventEmitter } from 'eventemitter3'
import { getLogger } from '@aas-dashboard/misc/logger'

const LOG = getLogger('async-update-ws-client')

export abstract class AsyncUpdateClient extends EventEmitter {
  protected socket: WebSocket | null = null

  protected connectionEvent: Promise<void>

  constructor (config: Configuration) {
    super()
    this.socket = new WebSocket(config.url)

    this.connectionEvent = new Promise((resolve, reject) => {
      if (this.socket) {
        this.socket.addEventListener('open', () => {
          resolve()
        })
      } else {
        reject('Failed to create socket')
      }
    })

    this.socket.addEventListener('message', (message: { data: any }) => {
      LOG.debug("Received message", message.data)
      this.handleMessage(JSON.parse(message.data))
    })
  }

  protected abstract handleMessage (message: { topic: string } & unknown): void

  protected async send(message: unknown): Promise<void> {
    await this.connectionEvent
    if (this.socket) {
      this.socket.send(JSON.stringify(message))
    } else {
      throw new Error('Socket closed')
    }
  }

  public close (): void {
    this.socket?.close()
    // TODO: duplicate measures for detecting closed connections
    this.connectionEvent = Promise.reject('Connection closed')
    this.socket = null
  }
}
