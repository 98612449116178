




























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Group, InviteInfo, Role } from '@aas-dashboard/repo-api/models'
import { groupRoles } from '@/components/admin/models'
import GroupStore from '@/store/modules/GroupStore'
import { DEFAULT_BACKEND_URL } from '@/constants'
import AlertStore, { Alert, AlertLvl } from '@/store/modules/AlertStore'

@Component
export default class GroupLinkModal extends Vue {
  @Prop() readonly dialog!: boolean
  @Prop() readonly group!: Group

  public links: InviteInfo[] = []
  public linkHeaders = [
    {
      text: 'Secret',
      align: 'start',
      sortable: false,
      value: 'inviteToken',
    },
    { text: 'Role', value: 'role' },
    { text: 'Expiration date', value: 'expires' },
    { text: '', value: 'actions', sortable: false }
  ]

  public newLinkRole = Role.Member
  public newLinkAge = 30
  public generatedToken = ''
  public disableGenerateButton = false

  get generatedLink (): string {
    return this.tokenToGroupLink(this.generatedToken)
  }

  get createInfo (): InviteInfo {
    return {
      role: this.newLinkRole,
      expires: new Date(new Date().getTime() + 86400000 * this.newLinkAge).toISOString()
    }
  }

  get roles (): Role[] {
    return groupRoles
  }

  @Watch('group')
  public async onGroupIdPropChange (): Promise<void> {
    this.generatedToken = ''
    this.disableGenerateButton = false
    if (this.group?.id) {
      this.links = await GroupStore.listGroupInviteLinks(this.group?.id)
    }
  }

  @Watch('newLinkRole')
  public newLinkRoleChanged (): void {
    this.disableGenerateButton = false
  }

  @Watch('newLinkAge')
  public newLinkAgeChanged (): void {
    this.disableGenerateButton = false
  }

  public async generateNewToken (): Promise<void> {
    const generatedLink = await GroupStore.generateGroupInviteToken({ group: this.group, info: this.createInfo })
    if (generatedLink) {
      this.links.push(generatedLink)
      this.generatedToken = generatedLink?.inviteToken ?? ''
      this.disableGenerateButton = true
    }
  }

  public async deleteLink (data : InviteInfo): Promise<void> {
    if (data?.inviteToken) {
      const confirmation = await this.$confirm('Are you sure you want to delete this invite link?<br/><br/>Warning: This cannot be undone.', { title: 'Delete invite link?', icon: '' })
      if (!confirmation) return
      await GroupStore.deleteGroupInviteToken({ groupId: this.group.id, inviteToken: data.inviteToken })
      await this.onGroupIdPropChange()
    }
  }

  public async copyLink (data : InviteInfo | undefined): Promise<void> {
    const token = data?.inviteToken ? data.inviteToken : this.generatedToken
    navigator.clipboard.writeText(this.tokenToGroupLink(token))
    AlertStore.pushAlert(new Alert(AlertLvl.INFO, 'Link copied'))
  }

  public isNumeric (text: string): string | boolean {
    const num = Number(text)
    return (Number.isFinite(num) && num > 0) || 'Must provide a positive number of days'
  }

  public mounted (): void {
    this.onGroupIdPropChange()
  }

  public async close (): Promise<void> {
    this.$emit('group-links-closed')
  }

  private tokenToGroupLink (token: string | undefined): string {
    return token ? `${DEFAULT_BACKEND_URL}invite?join_token=${token}` : ''
  }
}
