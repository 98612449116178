






















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Group, RoleBinding } from '@aas-dashboard/repo-api/models'
import GroupStore from '@/store/modules/GroupStore'
import AddGroupModal from '@/components/admin/groups/AddGroupModal.vue'
import EditGroupModal from '@/components/admin/groups/EditGroupModal.vue'
import EditGroupMembersModal from '@/components/admin/groups/EditGroupMembersModal.vue'
import ViewGroupTemplatesModal from '@/components/admin/groups/ViewGroupTemplatesModal.vue'
import InviteGroupMemberModal from '@/components/admin/groups/InviteGroupMemberModal.vue'
import { MyEventEmitter } from '@/event'
import RoleStore from '@/store/modules/RoleStore'
import { Role } from '@aas-dashboard/repo-api'

type GroupEntry = {
  id: string;
  name: string;
  myRoleBinding: RoleBinding | null;
  canEdit: boolean;
}

@Component({
  components: { AddGroupModal, EditGroupModal, ViewGroupTemplatesModal, EditGroupMembersModal, InviteGroupMemberModal }
})
export default class GroupsManagement extends Vue {
  @Prop() readonly platform!: boolean

  public search = ''
  public sortBy = 'id'
  public sortDesc = false

  public modalEvents = new MyEventEmitter()

  public showAddGroup = false
  public showViewGroupTemplates = false
  public showEditGroup = false
  public showEditGroupMembers = false

  public groupToEdit: Group | null = null
  public groupToEditRoleBinding: RoleBinding | null = null

  public headers = [
    // { text: 'Icon', value: 'thumbnailUrl', sortable: false, filterable: false },
    { text: 'Id', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: '', value: 'controls', sortable: false, align: 'right' }
  ]

  get groups (): GroupEntry[] {
    const groups: GroupEntry[] = []
    for (const group of Object.values(GroupStore.groups)) {
      if (this.platform || group.member) {
        const binding = RoleStore.myGroupRoleBindings[group.id]
        groups.push({ id: group.id, name: group.name, myRoleBinding: binding, canEdit: binding?.role === Role.GroupAdmin })
      }
    }
    return groups
  }

  @Watch('platform')
  public async onTargetChanged (): Promise<void> {
    await GroupStore.getGroups(this.platform)
  }

  public viewGroupTemplatesClick (group?: GroupEntry): void {
    if (!group) return
    this.groupToEdit = group
    this.showViewGroupTemplates = true
  }

  public editGroupClick (group?: GroupEntry): void {
    if (!group) return
    this.groupToEdit = group
    this.showEditGroup = true
  }

  public editGroupMembersClick (value?: GroupEntry): void {
    if (!value) return
    this.groupToEdit = value
    this.groupToEditRoleBinding = value.myRoleBinding
    this.showEditGroupMembers = true
  }

  public addGroupClick (): void {
    this.showAddGroup = true
  }

  public async onEditGroupClosed (editData: Group): Promise<void> {
    const oldGroupData: Group | null = this.groupToEdit
    this.groupToEdit = null
    this.groupToEditRoleBinding = null
    this.showEditGroup = false

    if (!oldGroupData || !editData) return
    await GroupStore.updateGroup({ group: editData, platform: this.platform })
  }

  public async onEditGroupMembersClosed (): Promise<void> {
    this.groupToEdit = null
    this.showEditGroupMembers = false
    this.modalEvents.emit('refresh-group-bindings')
  }

  public async onAddGroupClosed (editData: Group | undefined): Promise<void> {
    this.showAddGroup = false

    if (editData) {
      await GroupStore.createGroup({ group: editData, platform: this.platform })
      this.modalEvents.emit('refresh-group-bindings')
    }
  }

  public async onViewGroupTemplatesClosed (): Promise<void> {
    this.showViewGroupTemplates = false
    this.groupToEdit = null
  }

  public async deleteGroup (group: Group): Promise<void> {
    if (!group) return
    const confirmation = await this.$confirm(`Are you sure you want to delete the ${group.name} group?<br/><br/>Warning: This cannot be undone.`, { title: 'Delete group?', icon: '' })
    if (!confirmation) return
    await GroupStore.deleteGroup({ group: group, platform: this.platform })

    this.modalEvents.emit('refresh-group-bindings')
  }

  public async onRefreshGroupBindings (): Promise<void> {
    await RoleStore.updateMyState()
  }

  public async mounted (): Promise<void> {
    await GroupStore.getGroups(this.platform)
    await this.onRefreshGroupBindings()
    this.modalEvents.on('refresh-group-bindings', this.onRefreshGroupBindings)
  }
}
