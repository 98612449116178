import { ConceptDescription, ModelTypes } from '@aas-dashboard/i40-aas/models'
import { ResolvedReference } from '@aas-dashboard/api/models'
import { AASHash } from '@aas-dashboard/misc/types'

function hasShortName (cd: any): cd is ConceptDescription & { shortName: string } {
  return !!cd && !!cd.shortName && !!cd.identification
}

export function conceptDescriptionToShort (cd: ConceptDescription | null): string {
  return hasShortName(cd) ? cd.shortName : (cd?.idShort ?? '')
}

export function getAASHashFromResolvedReference (reference: ResolvedReference | null): AASHash | null {
  return reference && reference.path && reference.path.length > 0 && reference.path[0].modelType === ModelTypes.AssetAdministrationShell ? reference.path[0].value : null
}

export function getSubmodelIdShortFromResolvedReference (reference: ResolvedReference | null): AASHash | null {
  return reference && reference.path && reference.path.length > 1 && reference.path[1].modelType === ModelTypes.Submodel ? reference.path[1].value : null
}
