






































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AASTemplateDescriptor } from '@aas-dashboard/repo-api/models'
import { identifierToStr } from '@aas-dashboard/misc/utils/i40-strings'
import { DateTime } from 'luxon'

@Component
export default class MetadataPanel extends Vue {
  @Prop() readonly templateDescriptor!: AASTemplateDescriptor
  @Prop() readonly current!: boolean

  DateTime: typeof DateTime = DateTime

  get identifier (): string | null {
    if (this.templateDescriptor && this.templateDescriptor.identifier) {
      return identifierToStr(this.templateDescriptor.identifier)
    }
    return null
  }
}
