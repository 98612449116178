import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Configuration } from '@aas-dashboard/auth-api/configuration'
import store from '@/store'
import { UserInfo } from '@aas-dashboard/auth-api/models'
import { AxiosError, AxiosResponse } from 'axios'
import { DEFAULT_BACKEND_OPENAPI_AUTH_URL } from '@/constants'
import { AuthenticationApi } from '@aas-dashboard/auth-api'
import _, { isEqual } from 'lodash'

export interface IAuthStore {
  currentUserInfo: UserInfo | null
}

@Module({ namespaced: true, store: store, name: 'authstore', dynamic: true })
class AuthStore extends VuexModule implements IAuthStore {
  public currentUserInfo: UserInfo | null = null
  private authApiService: AuthenticationApi | null = null

  @Mutation
  public setupAPIService (): void {
    this.authApiService = new AuthenticationApi(new Configuration({
      basePath: DEFAULT_BACKEND_OPENAPI_AUTH_URL,
      baseOptions: {
        withCredentials: true
      }
    }))
  }

  @Mutation
  public setUserInfo (userInfo: UserInfo | null) {
    if (!isEqual(userInfo, this.currentUserInfo)) {
      console.log('New user state:', userInfo)
    }
    this.currentUserInfo = userInfo
  }

  get currentUserDisplayname () {
    return this.currentUserInfo?.displayName
  }

  get isUserSignedIn () {
    return this.currentUserInfo != null
  }

  @Action({ rawError: true })
  public async updateUserState () {
    if (this.authApiService === null) this.setupAPIService()
    let response: AxiosResponse<UserInfo> | null = null

    try {
      response = await this.authApiService?.whoami() ?? null
    } catch (err) {
      const axiosError = err as unknown as AxiosError
      if (axiosError?.response?.status === 403) {
        this.setUserInfo(null)
      } else {
        console.warn(`Unknown server response ${axiosError?.response}`)
        return
      }
    }

    if (response?.status === 200) {
      const user = response.data
      if (!_.isEqual(user, this.currentUserInfo)) {
        this.setUserInfo(response.data)
      }
    } else {
      console.warn('Unsupported response')
      // return
    }
  }

  @Action({ rawError: true })
  public async signOut () {
    if (this.authApiService === null) this.setupAPIService()
    try {
      if (this.isUserSignedIn) {
        await this.authApiService?.logout()
      }
    } catch (err) {
      console.warn('Failed to log out the current user')
      return
    } finally {
      // State may be undetermined on error, set to null to inform user.
      // User state update loop will reset state if appropriate.
      this.setUserInfo(null)
    }
  }
}

export default getModule(AuthStore)
