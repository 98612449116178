







import { Component, Prop, Vue, Inject, Watch } from 'vue-property-decorator'
import { Reference } from '@aas-dashboard/i40-aas/models'
import { referenceToStrArray } from '@aas-dashboard/misc/utils/i40-strings'
import { AASHash } from '@aas-dashboard/misc/types'
import { ResolvedReference } from '@aas-dashboard/api/models'
import { Location } from 'vue-router'
import Truncate from '../../view/Truncate.vue'
import { getAASHashFromResolvedReference } from '@aas-dashboard/frontend-components/aas/helpers'

@Component({ components: { Truncate } })
export default class ReferenceView extends Vue {
  @Prop() public readonly reference!: Reference
  @Inject() @Prop() public readonly referenceRouter!: (aasHash: AASHash) => Location
  @Inject() @Prop() public readonly referenceResolver!: (ref: Reference) => Promise<ResolvedReference | null>

  private resolvedReference: ResolvedReference | null = null

  @Watch('reference')
  private async updateResolvedReference (): Promise<void> {
    if (!this.reference) {
      this.resolvedReference = null
    } else {
      if (this.referenceResolver) {
        this.resolvedReference = await this.referenceResolver(this.reference)
      }
    }
  }

  public async mounted (): Promise<void> {
    await this.updateResolvedReference()
  }

  get idShortPath (): string {
    if (!this.resolvedReference) {
      return '<unknown>'
    }

    let res = '<unknown>'
    this.resolvedReference.path.forEach((item, index) => {
      if (index === 0) {
        res = item.label
      } else {
        res += ' -> ' + item.label
      }
    })
    return res
  }

  get targetAASHash (): AASHash | null {
    return getAASHashFromResolvedReference(this.resolvedReference)
  }

  get referenceRoute (): Location | null {
    if (this.referenceRouter && this.targetAASHash) {
      return this.referenceRouter(this.targetAASHash)
    }
    return null
  }

  get referenceToString (): string {
    const res = referenceToStrArray(this.reference, true)
    return res.length > 0 ? res.join(' -> ') : '<unknown>'
  }
}
