















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Asset } from '@aas-dashboard/i40-aas/models'
import { identifierToStr } from '@aas-dashboard/misc/utils/i40-strings'

@Component
export default class AssetPanel extends Vue {
  @Prop() readonly asset!: Asset
  @Prop() readonly thumbnail!: string

  get identifier (): string | null {
    if (this.asset && this.asset.identification) {
      return identifierToStr(this.asset.identification)
    }
    return null
  }
}
