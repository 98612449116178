














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  BearerSpec,
  BearerType,
  ResourceType,
  Role,
  RoleBinding
} from '@aas-dashboard/repo-api/models'
import RoleStore from '@/store/modules/RoleStore'
import { templateRoles } from '@/components/admin/models'
import { AASTemplateDescriptor, Group } from '@aas-dashboard/repo-api'
import GroupStore from '@/store/modules/GroupStore'

export type ItemType = {
  text: string,
  value: Group,
}

@Component
export default class InviteTemplateMemberModal extends Vue {
  @Prop() readonly dialog!: boolean
  @Prop() readonly template!: AASTemplateDescriptor
  @Prop() readonly currentRoleBindings!: RoleBinding[]

  public valid = false

  public newBearer: BearerSpec | null = null
  public newRole: Role = Role.Member

  public newGroup: Group | null = null

  public loading = false
  public items: ItemType[] = []

  public async getItems (): Promise<ItemType[]> {
    return (await GroupStore.getGroups())?.map(group => { return { text: group.name, value: group } })
      ?.filter(item => !this.currentRoleBindings?.find(binding => binding.bearer.bearerId === item.value.id)) ?? []
  }

  @Watch('template')
  public async onTemplatePropChange (): Promise<void> {
    this.newBearer = null
    this.newRole = Role.Member
    this.items = await this.getItems()
  }

  @Watch('dialog')
  public async opOpened (): Promise<void> {
    if (this.dialog) {
      await this.onTemplatePropChange()
    }
  }

  get roles (): Role[] {
    return templateRoles
  }

  public mustHaveGroup (value?: Group): true | string {
    if (!value) {
      return 'Must select a group'
    }
    return true
  }

  public async createRoleBinding (submit: boolean): Promise<void> {
    if (submit && this.newGroup && this.newGroup.id && this.template) {
      const binding: RoleBinding = {
        resource: {
          resourceId: this.template.hash,
          resourceType: ResourceType.Template
        },
        bearer: {
          bearerId: this.newGroup.id,
          bearerType: BearerType.Group
        },
        role: this.newRole
      }
      await RoleStore.addRoleBindingToTemplate({ templateHash: this.template.hash, binding })
    }
    this.$emit('invite-group-closed')
  }
}
