import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Configuration } from '@aas-dashboard/repo-api/configuration'
import store from '@/store'
import { DEFAULT_BACKEND_OPENAPI_URL } from '@/constants'
import { AccessApi, Role } from '@aas-dashboard/repo-api'
import { BearerType, ResourceType, RoleBinding } from '@aas-dashboard/repo-api/models'
import { axiosInstance } from '@/axios'
import { BASE_PATH } from '@aas-dashboard/repo-api/base'
import { AASTemplateHash } from '@aas-dashboard/misc/types'
import AuthStore from '@/store/modules/AuthStore'
import { Vue } from 'vue-property-decorator'

export interface IRoleStore {
  myPlatformRole: Role | null;
  myTemplateRoleBindings: { [key: string]: RoleBinding };
  myGroupRoleBindings: { [key: string]: RoleBinding };
}

@Module({ namespaced: true, store: store, name: 'rolestore', dynamic: true })
class RoleStore extends VuexModule implements IRoleStore {
  public myPlatformRole: Role | null = null
  public myTemplateRoleBindings: { [key: string]: RoleBinding } = {}
  public myGroupRoleBindings: { [key: string]: RoleBinding } = {}

  private accessApiService: AccessApi | null = null

  @Mutation
  public setupAPIService (): void {
    this.accessApiService = new AccessApi(new Configuration({
      basePath: DEFAULT_BACKEND_OPENAPI_URL,
      baseOptions: {
        withCredentials: true
      }
    }), BASE_PATH, axiosInstance)
  }

  @Mutation
  private setMyPlatformRole (role: Role): void {
    this.myPlatformRole = role
  }

  @Mutation
  private setMyTemplateRoleBindings (bindings: RoleBinding[]): void {
    for (const binding of bindings) {
      if (binding.resource.resourceType === ResourceType.Template) {
        Vue.set(this.myTemplateRoleBindings, binding.resource.resourceId, binding)
      }
    }
  }

  @Mutation
  private setMyGroupRoleBindings (bindings: RoleBinding[]): void {
    for (const binding of bindings) {
      if (binding.resource.resourceType === ResourceType.Group) {
        Vue.set(this.myGroupRoleBindings, binding.resource.resourceId, binding)
      }
    }
  }

  get isGuestAtleast (): boolean {
    return this.validatePlatformRole(Role.Guest)
  }

  get isMemberAtleast (): boolean {
    return this.validatePlatformRole(Role.Member)
  }

  get isPlatformAdmin (): boolean {
    return this.validatePlatformRole(Role.PlatformAdmin)
  }

  get validatePlatformRole () {
    return (role: Role) => {
      const allRoles = [Role.Anonymous, Role.Guest, Role.Member, Role.Editor, Role.GroupAdmin, Role.PlatformAdmin]
      const verdict = this.myPlatformRole ? allRoles.indexOf(role) <= allRoles.indexOf(this.myPlatformRole) : false
      return verdict
    }
  }

  @Action({ rawError: true })
  private async updateMyTemplateRoleBindings (): Promise<void> {
    if (AuthStore.currentUserInfo) {
      const bindings = await this.getAllTemplateRoleBindingsForUserId(AuthStore.currentUserInfo?.id)
      const allRoles = Object.values(Role)
      if (bindings) {
        const effectiveBindings: Map<string, RoleBinding> = new Map<string, RoleBinding>()
        for (const binding of bindings) {
          const effectiveBinding = effectiveBindings.get(binding.resource.resourceId)
          if (!effectiveBinding || (allRoles.indexOf(binding.role) > allRoles.indexOf(effectiveBinding.role) || (allRoles.indexOf(binding.role) === allRoles.indexOf(effectiveBinding.role) && binding.bearer.bearerType === BearerType.User))) {
            effectiveBindings.set(binding.resource.resourceId, binding)
          }
        }
        this.setMyTemplateRoleBindings(Array.from(effectiveBindings.values()))
      }
    }
  }

  @Action({ rawError: true })
  private async updateMyGroupRoleBindings (): Promise<void> {
    if (AuthStore.currentUserInfo) {
      const bindings = await this.getAllGroupRoleBindingsForUserId(AuthStore.currentUserInfo?.id)
      const allRoles = Object.values(Role)
      if (bindings) {
        const effectiveBindings: Map<string, RoleBinding> = new Map<string, RoleBinding>()
        for (const binding of bindings) {
          const effectiveBinding = effectiveBindings.get(binding.resource.resourceId)
          if (!effectiveBinding || (allRoles.indexOf(binding.role) > allRoles.indexOf(effectiveBinding.role) || (allRoles.indexOf(binding.role) === allRoles.indexOf(effectiveBinding.role) && binding.bearer.bearerType === BearerType.User))) {
            effectiveBindings.set(binding.resource.resourceId, binding)
          }
        }
        this.setMyGroupRoleBindings(Array.from(effectiveBindings.values()))
      }
    }
  }

  @Action({ rawError: true })
  public async getRoleBindingsGroup (groupId: string): Promise<RoleBinding[] | null> {
    if (this.accessApiService === null) this.setupAPIService()

    let bindings: RoleBinding[] | null = null
    try {
      bindings = (await this.accessApiService?.retrieveGroupRoleBindings(groupId))?.data ?? null
    } catch (e) {
      console.warn('Failed to retrieve RoleBindings for group', groupId)
    }
    return bindings
  }

  @Action({ rawError: true })
  public async removeRolebindingFromGroup (binding: RoleBinding): Promise<void> {
    if (this.accessApiService === null) this.setupAPIService()

    if (binding.resource.resourceType !== ResourceType.Group) throw new Error('Unexpected resourceType')

    try {
      await this.accessApiService?.removeGroupRoleBindingByBearer(binding.resource.resourceId, binding.bearer.bearerType, binding.bearer.bearerId)
    } catch (e) {
      console.warn(`Failed to remove removeGroupRolebindingByBearer for ${binding}`)
    }
  }

  @Action({ rawError: true })
  public async addRoleBindingToGroup ({ groupId, binding }: { groupId: string, binding: RoleBinding }): Promise<void> {
    if (this.accessApiService === null) this.setupAPIService()

    try {
      await this.accessApiService?.addGroupRoleBinding(groupId, binding)
    } catch (e) {
      console.warn('Failed to add RoleBindings for group', groupId, e)
    }
  }

  @Action({ rawError: true })
  public async getUserRoleBindings (userId: string): Promise<RoleBinding[]> {
    if (this.accessApiService === null) this.setupAPIService()

    return (await this.accessApiService!.retrieveUserRoleBindings(userId)).data ?? []
  }

  @Action({ rawError: true })
  public async getAllTemplateRoleBindingsForUserId (userId: string): Promise<RoleBinding[] | null> {
    if (this.accessApiService === null) this.setupAPIService()

    let bindings: RoleBinding[] | null = null
    try {
      bindings = (await this.accessApiService?.retrieveAllTemplateRoleBindings(userId))?.data ?? null
    } catch (e) {
      console.warn('Failed to retrieve RoleBindings for templates')
    }
    return bindings
  }

  @Action({ rawError: true })
  public async getAllGroupRoleBindingsForUserId (userId: string): Promise<RoleBinding[] | null> {
    if (this.accessApiService === null) this.setupAPIService()

    let bindings: RoleBinding[] | null = null
    try {
      bindings = (await this.accessApiService?.retrieveAllGroupRoleBindings(userId))?.data ?? null
    } catch (e) {
      console.warn('Failed to retrieve RoleBindings for templates')
    }
    return bindings
  }

  @Action({ rawError: true })
  public async getRoleBindingsTemplate (templateHash: string): Promise<RoleBinding[] | null> {
    if (this.accessApiService === null) this.setupAPIService()

    let bindings: RoleBinding[] | null = null
    try {
      bindings = (await this.accessApiService?.retrieveTemplateRoleBindings(templateHash))?.data ?? null
    } catch (e) {
      console.warn('Failed to retrieve RoleBindings for template', templateHash)
    }
    return bindings
  }

  @Action({ rawError: true })
  public async addRoleBindingToTemplate ({ templateHash, binding }: { templateHash: AASTemplateHash, binding: RoleBinding }): Promise<void> {
    if (this.accessApiService === null) this.setupAPIService()

    try {
      await this.accessApiService?.addTemplateRoleBinding(templateHash, binding)
    } catch (e) {
      console.warn('Failed to add RoleBindings for template', templateHash, e)
    }
  }

  @Action({ rawError: true })
  public async onboardUserWithToken (inviteToken: string): Promise<string | undefined> {
    if (AuthStore.currentUserInfo) {
      if (this.accessApiService === null) this.setupAPIService()

      try {
        const response = await this.accessApiService?.onboardUser({ userId: AuthStore.currentUserInfo.id, inviteToken: inviteToken })
        switch (response?.status) {
          case 200:
            return 'User was already onboarded.'
          case 201:
            return 'User successfully onboarded.'
          default:
            return 'Undefined result'
        }
      } catch (e) {
        console.warn('Unable to process invite token', e)
      }
    }
  }

  @Action({ rawError: true })
  public async removeRolebindingFromTemplate (binding: RoleBinding): Promise<void> {
    if (this.accessApiService === null) this.setupAPIService()

    if (binding.resource.resourceType !== ResourceType.Template) throw new Error('Unexpected resourceType')

    try {
      await this.accessApiService?.removeTemplateRoleBindingByBearer(binding.resource.resourceId, binding.bearer.bearerType, binding.bearer.bearerId)
    } catch (e) {
      console.warn(`Failed to remove removeRolebindingFromTemplate for ${binding}`)
    }
  }

  @Action({ rawError: true })
  public async updateMyState (): Promise<void> {
    if (AuthStore.currentUserInfo) {
      const roleBindings = await this.getUserRoleBindings(AuthStore.currentUserInfo.id)
      const platformRoles = roleBindings.filter(binding => binding.resource.resourceType === ResourceType.Platform)
      if (platformRoles.length !== 1) {
        console.error('Platform role unclear', platformRoles)
      } else if (this.myPlatformRole !== platformRoles[0].role) {
        this.setMyPlatformRole(platformRoles[0].role)
      }
      await this.updateMyGroupRoleBindings()
      await this.updateMyTemplateRoleBindings()
    } else {
      this.setMyPlatformRole(Role.Anonymous)
      this.setMyGroupRoleBindings([])
      this.setMyTemplateRoleBindings([])
    }
  }
}

export default getModule(RoleStore)
