













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import GoogleSigninButton from '@/components/auth/GoogleSigninButton.vue'
import AuthStore from '@/store/modules/AuthStore'
import RoleStore from '@/store/modules/RoleStore'
import AlertStore, { Alert, AlertLvl } from '@/store/modules/AlertStore'

@Component({
  components: {
    GoogleSigninButton
  }
})
export default class Onboard extends Vue {
  @Prop() readonly token!: string

  private processMsg = 'Onboarding user...'

  get isSignedIn (): boolean {
    return AuthStore.isUserSignedIn
  }

  @Watch('isSignedIn')
  public async onboardUser (): Promise<void> {
    if (this.isSignedIn && this.token) {
      const message = await RoleStore.onboardUserWithToken(this.token)
      this.processMsg = message || 'Unable to onboard, please check your link and refresh this page.'
      if (message) {
        AlertStore.pushAlert(new Alert(AlertLvl.INFO, this.processMsg))
        await RoleStore.updateMyState()
        this.$router.push('/')
      }
    }
  }

  public async mounted (): Promise<void> {
    await this.onboardUser()
  }
}
