import {
  Asset, Key, KeyElements,
  ModelTypes, Property, Range,
  ReferenceElement,
  RelationshipElement,
  Submodel, AssetAdministrationShell,
  Blob, FileElement,
  MultiLanguageProperty,
  Reference,
  Identifier,
  SubmodelElement
} from "@aas-dashboard/i40-aas/models";
import {ExtendedSubmodelElement, ExtendedSubmodelElementCollection} from "@aas-dashboard/misc/types";

export function isReferenceElement (ref: any): ref is ReferenceElement {
  return !!ref && !!ref.modelType && ref.modelType.name === ModelTypes.ReferenceElement
}

export function isRelationshipElement (rel: any): rel is RelationshipElement {
  return !!rel && rel.first && rel.second
}

export function isAsset (a: any): a is Asset {
  return !!a && a.modelType.name === ModelTypes.Asset
}

export function isAas (a: any): a is AssetAdministrationShell {
  return !!a && a.modelType.name === ModelTypes.AssetAdministrationShell
}

export function isSubmodel (se: any): se is Submodel {
  return !!se && se.modelType.name === ModelTypes.Submodel
}

export function isSubmodelElementCollection (se: any): se is ExtendedSubmodelElementCollection {
  return !!se && se.modelType.name === ModelTypes.SubmodelElementCollection
}

export function isProperty(submodelElement: any): submodelElement is Property  {
  return !!submodelElement && submodelElement.modelType.name === ModelTypes.Property
}

export function isOperation(submodelElement: any): submodelElement is Property  {
  return !!submodelElement && submodelElement.modelType.name === ModelTypes.Operation
}

export function isRange(submodelElement: any): submodelElement is Range  {
  return !!submodelElement && submodelElement.modelType.name === ModelTypes.Range
}

export function isFile (f: any): f is FileElement {
  return !!f && f.modelType.name === ModelTypes.File
}

export function isBlob (b: any): b is Blob {
  return !!b && b.modelType.name === ModelTypes.Blob
}

export function isDataElementKey (key: Key): boolean {
  return [KeyElements.DataElement, KeyElements.Property, KeyElements.MultiLanguageProperty, KeyElements.Range, KeyElements.ReferenceElement, KeyElements.File, KeyElements.Blob].includes(key.type)
}

export function isSubmodelElementKey (key: Key): boolean {
  return [KeyElements.SubmodelElement, KeyElements.SubmodelElementCollection, KeyElements.RelationshipElement, KeyElements.AnnotatedRelationshipElement, KeyElements.Capability, KeyElements.Operation, KeyElements.Event, KeyElements.BasicEvent, KeyElements.Entity].includes(key.type) || isDataElementKey(key)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSemanticId (semanticID: any): semanticID is Key {
  return semanticID !== undefined && semanticID.type !== undefined && semanticID.value !== undefined && semanticID.local !== undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAssetAdministrationShell (aas: any): aas is AssetAdministrationShell {
  return aas !== undefined && aas.asset !== undefined && aas.derivedFrom !== undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isIdentifier (identifier: any): identifier is Identifier {
  return identifier !== undefined && identifier.id !== undefined && typeof identifier.id === 'string' && identifier.idType !== undefined // idType is rarely used, I think?
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isKey (key: any): key is Key {
  return key !== undefined && key.type !== undefined && key.idType !== undefined && key.value !== undefined && key.local !== undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isReference (ref: any): ref is Reference {
  return ref !== undefined && ref.keys !== undefined && ref.keys.length > 0 && isKey(ref.keys[0])
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isMultiLanguageProperty (p: any): p is MultiLanguageProperty {
  return p !== undefined && p.modelType.name === ModelTypes.MultiLanguageProperty
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isExtendedSubmodelElement (p: any): p is ExtendedSubmodelElement {
  return p != null && p.modelType?.name != null && p.sepath != null
}
