// tslint:disable
/**
 * Asset Administration Shell REST-API
 * This is going to be the official Asset Administration Shell REST-API as part of Details of the Asset Administration Shell Part 2
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    messageType?: MessageMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    text?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageMessageTypeEnum {
    Unspecified = 'Unspecified',
    Debug = 'Debug',
    Information = 'Information',
    Warning = 'Warning',
    Error = 'Error',
    Fatal = 'Fatal',
    Exception = 'Exception'
}



