import axios, { AxiosInstance } from 'axios'
import AlertStore, { Alert, AlertLvl } from '@/store/modules/AlertStore'

export const axiosInstance: AxiosInstance = axios.create()

axiosInstance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  let handled = false
  if (error.response) {
    // if (error.response.status === 403) {
    //   AlertStore.pushAlert(new Alert(AlertLvl.ERROR, 'Access denied in backend, please login.'))
    //   handled = true
    // }
  } else if (error.request) {
    AlertStore.pushAlert(new Alert(AlertLvl.ERROR, 'No connection with backend'))
    handled = true
  }
  if (!handled) {
    const msg = error.response?.data?.error?.message ?? error.message
    console.error(error.response?.status, error.message, msg)
    AlertStore.pushAlert(new Alert(AlertLvl.ERROR, `Backend responded with failure: ${msg}`))
  }
  return Promise.reject(error)
})
