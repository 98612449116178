


























import { Component, Vue, Watch } from 'vue-property-decorator'
import InviteMemberModal from '@/components/template/management/InviteMemberModal.vue'
import InviteGroupModal from '@/components/template/management/InviteGroupModal.vue'
import EditTemplatePermissionsModal from '@/components/template/management/EditTemplatePermissionsModal.vue'
import {
  TemplateProcessingState
} from '@aas-dashboard/repo-api'
import AASTemplateStore from '@/store/modules/AASTemplateStore'
import AuthStore from '@/store/modules/AuthStore'
import RoleStore from '@/store/modules/RoleStore'
import UserStore from '@/store/modules/UserStore'

type ProcessingStateEntry = TemplateProcessingState & { displayName: string | '<UNKNOWN>' }

@Component({
  components: { EditTemplatePermissionsModal, InviteMemberModal, InviteGroupModal }
})
export default class ProcessingStates extends Vue {
  AuthStore: typeof AuthStore = AuthStore
  RoleStore: typeof RoleStore = RoleStore

  private processingStateHeaders = [
    { text: 'Upload data', value: 'uploadedOn' },
    { text: 'User', value: 'displayName' },
    { text: 'Filename', value: 'fileName' },
    { text: 'Revision', value: 'revision' },
    { text: 'State', value: 'status' },
    { text: 'Message', value: 'statusMsg' }
  ]

  private processingStates: ProcessingStateEntry[] = []

  private openAASTemplate (value: TemplateProcessingState): void {
    if (value.hash) {
      this.$router.push({
        name: 'aasTemplateView',
        params: { aasTemplateHash: value.hash, revision: value.revision }
      })
    } else {
      console.warn('Template hash unknown, cannot change route')
    }
  }

  public async refreshProcessingStates (): Promise<void> {
    const states = AuthStore.isUserSignedIn && RoleStore.isPlatformAdmin ? (await AASTemplateStore.getProcessingStates(undefined, 100) || []) : []
    const userIds = states.map(state => state.uploader.bearerId)
    await UserStore.getUsers(userIds)

    this.processingStates = states.map((state: TemplateProcessingState) => { return { ...state, displayName: UserStore.users[state.uploader.bearerId]?.displayName || '<UNKNOWN>' } })
  }

  @Watch('AuthStore.isUserSignedIn')
  @Watch('RoleStore.isPlatformAdmin')
  public async onUserChanged (): Promise<void> {
    await this.refreshProcessingStates()
  }

  public async mounted (): Promise<void> {
    await this.refreshProcessingStates()
  }
}
