// tslint:disable
/**
 * Asset Administration Shell REST-API
 * This is going to be the official Asset Administration Shell REST-API as part of Details of the Asset Administration Shell Part 2
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Reference } from './reference';

/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {Reference}
     * @memberof Permission
     */
    permission: Reference;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    kindOfPermission: PermissionKindOfPermissionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PermissionKindOfPermissionEnum {
    Allow = 'Allow',
    Deny = 'Deny',
    NotApplicable = 'NotApplicable',
    Undefined = 'Undefined'
}



