













import { Component, Prop, Vue } from 'vue-property-decorator'
import VClamp from 'vue-clamp'

@Component({
  components: { VClamp }
})
export default class Truncate extends Vue {
  @Prop() readonly maxLines!: number | undefined
  @Prop() readonly maxWidth!: string | undefined
  @Prop() readonly minWidth!: string | undefined

  get _maxLines () : number {
    return this.maxLines ?? 3
  }

  get _style (): { 'max-width'?: string; 'min-width'?: string } {
    let style = {}
    if (this.maxWidth) {
      style = { ...style, ...{ 'max-width': `${this.maxWidth}` } }
    }
    if (this.minWidth) {
      style = { ...style, ...{ 'min-width': `${this.minWidth}` } }
    }
    return style
  }
}
