import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'
import { IAASTemplateStore } from '@/store/modules/AASTemplateStore'
import { IAlertStore } from '@/store/modules/AlertStore'
import { IAuthStore } from '@/store/modules/AuthStore'
import { IDescriptorStore } from '@/store/modules/DescriptorStore'
import { IUserStore } from '@/store/modules/UserStore'
import { IConceptDescriptionStore } from '@aas-dashboard/frontend-components/store/IConceptDescriptionStore'
import { IGroupStore } from '@/store/modules/GroupStore'
import { IRoleStore } from '@/store/modules/RoleStore'

Vue.use(Vuex)

const debug = false // Change me for debugging vuex

export interface IRootState {
  aastemplatestore: IAASTemplateStore;
  descriptorstore: IDescriptorStore;
  conceptdescriptionstore: IConceptDescriptionStore;
  alertStore: IAlertStore;
  authStore: IAuthStore;
  userstore: IUserStore;
  groupstore: IGroupStore;
  rolestore: IRoleStore;
}

export default new Vuex.Store<IRootState>({
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
