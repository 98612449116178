





































































































































































































import { Component, Vue, Provide, Watch } from 'vue-property-decorator'
import AASTemplateView from '@/components/template/aas/AASTemplateView.vue'
import AlertSnackbar from '@/components/AlertSnackbar.vue'
import { AASTemplateHash } from '@aas-dashboard/misc/types'
import DescriptorStore from '@/store/modules/DescriptorStore'
import { ResolvedReference } from '@aas-dashboard/api/models'
import { Reference } from '@aas-dashboard/i40-aas/models'
import { Location } from 'vue-router'
import GoogleSigninButton from '@/components/auth/GoogleSigninButton.vue'
import SessionKeepalive from '@/components/auth/SessionKeepalive.vue'
import AuthStore from '@/store/modules/AuthStore'
import ConceptDescriptionStore from '@/store/modules/ConceptDescriptionStore'
import { Role } from '@aas-dashboard/repo-api'
import RoleStore from '@/store/modules/RoleStore'
import AASTemplateStore from '@/store/modules/AASTemplateStore'
import { AAS_TEMPLATE_REPO_TITLE } from '@/constants'
import { MetaInfo } from 'vue-meta'

@Component({
  components: {
    AlertSnackbar,
    AASTemplateView,
    GoogleSigninButton,
    SessionKeepalive
  }
})
export default class App extends Vue {
  AuthStore: typeof AuthStore = AuthStore
  RoleStore: typeof RoleStore = RoleStore
  Role: typeof Role = Role

  @Provide() public referenceRouter (aasTemplateHash: AASTemplateHash): Location {
    return {
      name: 'aasTemplateView',
      params: {
        aasTemplateHash
      }
    }
  }

  @Provide() async referenceResolver (ref: Reference): Promise<ResolvedReference | null> {
    const resolvedReference = await AASTemplateStore.resolveReference(ref)
    if (!resolvedReference) {
      console.warn('Could NOT resolve reference', ref)
      return null
    }
    return resolvedReference
  }

  async setup (): Promise<void> {
    console.log('Setup start')
    await DescriptorStore.setupAASTemplateDescriptorsMonitoring(true)
    await ConceptDescriptionStore.setupConceptDescriptionsMonitoring(true)
    console.log('Setup done')
  }

  get skipRoleBindings (): boolean {
    return window.location.pathname.includes('invite')
  }

  @Watch('AuthStore.isUserSignedIn')
  async onCurrentUserChange (): Promise<void> {
    console.log('onCurrentUserChange', AuthStore.currentUserInfo)
    await AuthStore.updateUserState()
    if (!this.skipRoleBindings) {
      await RoleStore.updateMyState()
    }

    if (AuthStore.isUserSignedIn) {
      await this.setup()
    }
  }

  async mounted (): Promise<void> {
    await this.onCurrentUserChange()
  }

  public metaInfo (): MetaInfo {
    return {
      title: AAS_TEMPLATE_REPO_TITLE,
      meta: [
        { name: 'description', content: 'Share Asset Administration Shell (AAS) Templates in a simple manner. Outside of the possibility to upload, download and share, the repository serves to search for an already available model if you need one.' },
        { property: 'og:title', content: 'AAS Template Repository - Share Asset Administration Shell (AAS) Templates in a simple manner' },
        { property: 'og:site_name', content: 'AAS Template Repository' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  }
}
