


























































































import { Component, Vue } from 'vue-property-decorator'
import RoleStore from '@/store/modules/RoleStore'
import AuthStore from '@/store/modules/AuthStore'

@Component
export default class Home extends Vue {
  RoleStore: typeof RoleStore = RoleStore

  get showLoginHelp (): boolean {
    return AuthStore.isUserSignedIn && !RoleStore.isGuestAtleast
  }

  get showPublic (): boolean {
    return !AuthStore.isUserSignedIn || !RoleStore.isGuestAtleast
  }
}
