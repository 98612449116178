



















































import { Component, Prop, Vue, Provide } from 'vue-property-decorator'
import { Submodel, SubmodelElement } from '@aas-dashboard/i40-aas/models'
import OperationList from './OperationList.vue'
import SingularListItem from './SingularListItem.vue'
import CollectionListItem from './CollectionListItem.vue'
import { isSubmodelElementCollection, isOperation } from '@aas-dashboard/misc/guards/i40-models'
import { AASHash } from '@aas-dashboard/misc/types'
import { referenceToStr } from '@aas-dashboard/misc/utils/i40-strings'
import { IConceptDescriptionStore } from '@aas-dashboard/frontend-components/store/IConceptDescriptionStore'
import SemanticTooltip from './SemanticTooltip.vue'

@Component({
  components: { CollectionListItem, SingularListItem, SemanticTooltip, OperationList }
})

export default class SubmodelPanel extends Vue {
  @Prop() readonly submodel!: Submodel
  @Prop() readonly aasHash!: AASHash
  @Provide() @Prop() readonly binaryUrlTemplate!: (aasHash: AASHash, submodelId: string, sePath: string) => string
  @Prop() readonly showValues!: boolean
  @Prop() readonly showConceptDescriptions!: boolean
  @Prop() readonly conceptDescriptionStore!: IConceptDescriptionStore

  public isCollection (se: SubmodelElement): boolean {
    return isSubmodelElementCollection(se)
  }

  public isAnOperation (se: SubmodelElement): boolean {
    return isOperation(se)
  }

  public get semanticIdStr (): string | '<unknown>' {
    return referenceToStr(this.submodel.semanticId, false)
  }
}
