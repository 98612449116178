







import { Component, Inject } from 'vue-property-decorator'
import BaseView from './BaseView'
import { Blob } from '@aas-dashboard/i40-aas/models'
import { isBlob, isExtendedSubmodelElement } from '@aas-dashboard/misc/guards/i40-models'
import { AASHash } from '@aas-dashboard/misc/types'
import Truncate from '../../view/Truncate.vue'

@Component({ components: { Truncate } })
// TODO: Should we create a preview of the Blob? What is the difference with a File?
export default class BlobView extends BaseView<Blob> {
  @Inject() readonly binaryUrlTemplate!: (aasHash: AASHash, submodelId: string, sePath: string) => string

  get bloblUrl (): string | null {
    if (isExtendedSubmodelElement(this.submodelElement)) {
      const url = this.binaryUrlTemplate(this.aasHash, this.submodelId, this.submodelElement.sepath)
      return url ? `${url}?returnType=BLOB` : null
    } else {
      return null
    }
  }

  get blobStr (): string {
    if (isBlob(this.submodelElement)) {
      return this.submodelElement.value
    } else {
      return 'Error: Not a Blob object'
    }
  }

  public gotoUrl (url: string): void {
    window.open(url, '_blank')
  }
}
