
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  BearerSpec,
  BearerType,
  ResourceType,
  Role,
  RoleBinding,
  User
} from '@aas-dashboard/repo-api/models'
import _ from 'lodash'
import UserStore from '@/store/modules/UserStore'
import RoleStore from '@/store/modules/RoleStore'
import { templateRoles } from '@/components/admin/models'
import { AASTemplateDescriptor } from '@aas-dashboard/repo-api'

export type ItemType = {
  text: string,
  value: User,
}

@Component
export default class InviteMemberModal extends Vue {
  @Prop() readonly dialog!: boolean
  @Prop() readonly template!: AASTemplateDescriptor
  @Prop() readonly currentRoleBindings!: RoleBinding[]

  public valid = false

  public newBearer: BearerSpec | null = null
  public newRole: Role = Role.Member

  public newMember: User | null = null

  public loading = false
  public search: string | null = null
  public items: ItemType[] = []
  public debounceSearch = _.debounce(this.searchNameOrEmail, 500)

  public async searchNameOrEmail (needle: string | null): Promise<void> {
    if (needle) {
      if (needle.length <= 2) {
        this.items = []
      } else if (needle.toLowerCase() === this.newMember?.displayName) {
        // nothing to do
      } else {
        this.loading = true
        if (needle.includes('@')) {
          const usr: User | undefined = await UserStore.findUserByEmailExact(needle)
          if (usr) {
            this.items = [{ text: usr.displayName, value: usr }]
            this.newMember = usr
          } else {
            this.items = []
          }
        } else {
          const users: User[] = await UserStore.findUsersByDisplayName(needle)
          const bearerIds = this.currentRoleBindings ? this.currentRoleBindings.map(binding => binding.bearer.bearerId) : []
          this.items = users.filter(usr => !bearerIds.includes(usr.id)).map(usr => {
            return { text: usr.displayName, value: usr }
          })
        }
        this.loading = false
      }
    }
  }

  @Watch('template')
  public async onTemplatePropChange (): Promise<void> {
    this.newBearer = null
    this.newRole = Role.Member
  }

  @Watch('search')
  public async onSearchChange (val: string): Promise<void> {
    await this.debounceSearch(val)
  }

  get roles (): Role[] {
    return templateRoles
  }

  public mustHaveUser (value?: User): true | string {
    if (!value) {
      return 'Must select a member'
    }
    return true
  }

  public async createRoleBinding (submit: boolean): Promise<void> {
    if (submit && this.newMember && this.newMember.id && this.template) {
      const binding: RoleBinding = {
        resource: {
          resourceId: this.template.hash,
          resourceType: ResourceType.Template
        },
        bearer: {
          bearerId: this.newMember.id,
          bearerType: BearerType.User
        },
        role: this.newRole
      }
      await RoleStore.addRoleBindingToTemplate({ templateHash: this.template.hash, binding })
    }
    this.$emit('invite-member-closed')
  }
}
