






























import { Component, Vue, Prop } from 'vue-property-decorator'
import { ConceptDescription } from '@aas-dashboard/i40-aas/models'
import { identifierToStr } from '@aas-dashboard/misc/utils/i40-strings'
import Truncate from '../../view/Truncate.vue'

@Component({
  components: { Truncate }
})
export default class ConceptDescriptionView extends Vue {
  @Prop() readonly conceptDescription!: ConceptDescription
  @Prop() readonly altSemanticId!: string

  identifierToStr: typeof identifierToStr = identifierToStr

  get conceptDescriptionIdentifier (): string {
    return identifierToStr(this.conceptDescription.identification)
  }
}
