





















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Group } from '@aas-dashboard/repo-api/models'

@Component
export default class EditGroupModal extends Vue {
  @Prop() readonly dialog!: boolean
  @Prop() public readonly group: Group | null = null

  public newGroup: Group = {
    id: '',
    name: ''
  }

  @Watch('group')
  public async onGroupPropChange (): Promise<void> {
    if (this.group) {
      this.newGroup = {
        ...this.group
      }
    }
  }
}
