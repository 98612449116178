// tslint:disable
/**
 * Asset Administration Shell REST-API
 * This is going to be the official Asset Administration Shell REST-API as part of Details of the Asset Administration Shell Part 2
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Reference } from './reference';

/**
 * 
 * @export
 * @interface ValueObject
 */
export interface ValueObject {
    /**
     * 
     * @type {string}
     * @memberof ValueObject
     */
    value?: string;
    /**
     * 
     * @type {Reference}
     * @memberof ValueObject
     */
    valueId?: Reference;
    /**
     * 
     * @type {string}
     * @memberof ValueObject
     */
    valueType?: ValueObjectValueTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ValueObjectValueTypeEnum {
    AnyUri = 'anyUri',
    Base64Binary = 'base64Binary',
    Boolean = 'boolean',
    Date = 'date',
    DateTime = 'dateTime',
    DateTimeStamp = 'dateTimeStamp',
    Decimal = 'decimal',
    Integer = 'integer',
    Long = 'long',
    Int = 'int',
    Short = 'short',
    Byte = 'byte',
    NonNegativeInteger = 'nonNegativeInteger',
    PositiveInteger = 'positiveInteger',
    UnsignedLong = 'unsignedLong',
    UnsignedInt = 'unsignedInt',
    UnsignedShort = 'unsignedShort',
    UnsignedByte = 'unsignedByte',
    NonPositiveInteger = 'nonPositiveInteger',
    NegativeInteger = 'negativeInteger',
    Double = 'double',
    Duration = 'duration',
    DayTimeDuration = 'dayTimeDuration',
    YearMonthDuration = 'yearMonthDuration',
    Float = 'float',
    GDay = 'gDay',
    GMonth = 'gMonth',
    GMonthDay = 'gMonthDay',
    GYear = 'gYear',
    GYearMonth = 'gYearMonth',
    HexBinary = 'hexBinary',
    NOTATION = 'NOTATION',
    QName = 'QName',
    String = 'string',
    NormalizedString = 'normalizedString',
    Token = 'token',
    Language = 'language',
    Name = 'Name',
    NCName = 'NCName',
    ENTITY = 'ENTITY',
    ID = 'ID',
    IDREF = 'IDREF',
    NMTOKEN = 'NMTOKEN',
    Time = 'time'
}



