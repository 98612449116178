

































import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  ConceptDescriptionForLanguage,
  IConceptDescriptionStore
} from '../store/IConceptDescriptionStore'
import { referenceToStr } from '@aas-dashboard/misc/utils/i40-strings'
import {
  HasSemantics, LangString,
  Referable
} from '@aas-dashboard/i40-aas/models'
import Truncate from '../view/Truncate.vue'

@Component({ components: { Truncate } })
export default class SemanticTooltip extends Vue {
  @Prop() readonly conceptDescriptionStore!: IConceptDescriptionStore
  @Prop() readonly element!: HasSemantics & Referable & { descriptions: Array<LangString> }
  @Prop() readonly header!: boolean

  public get conceptDescriptionSummary (): ConceptDescriptionForLanguage | null {
    return this.conceptDescriptionStore.getConceptDescriptionForLanguageByReference({ reference: this.element?.semanticId, language: 'en' })
  }

  public get semanticIdStr (): string | '<unknown>' {
    return referenceToStr(this.element?.semanticId, false)
  }

  public get description (): string | null {
    if (!this.element || ((!this.element.description || this.element.description.length === 0) && (!this.element.descriptions || this.element.descriptions.length === 0))) return null

    const candidates: Array<LangString> | undefined = this.element.descriptions ? this.element.descriptions : this.element.description
    return candidates?.find(candidate => candidate.language.toLowerCase() === 'en')?.text ?? null
  }
}
