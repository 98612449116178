// tslint:disable
/**
 * Asset Administration Shell REST-API
 * This is going to be the official Asset Administration Shell REST-API as part of Details of the Asset Administration Shell Part 2
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum KeyType {
    Custom = 'Custom',
    IRDI = 'IRDI',
    IRI = 'IRI',
    IdShort = 'IdShort',
    FragmentId = 'FragmentId'
}



