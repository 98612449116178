import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/components/Home.vue'
import AASTemplateView from '@/components/template/aas/AASTemplateView.vue'
import AASTemplateList from '@/components/template/aas/AASTemplateList.vue'
import UploadAASTemplate from '@/components/template/management/UploadAASTemplate.vue'
import MyTemplateList from '@/components/template/management/MyTemplateList.vue'
import UsersManagement from '@/components/admin/users/UsersManagement.vue'
import GroupsManagement from '@/components/admin/groups/GroupsManagement.vue'
import Onboard from '@/components/admin/groups/Onboard.vue'
import About from '@/components/About.vue'
import LicensesView from '@aas-dashboard/frontend-components/view/LicensesView.vue'
import ProcessingStates from '@/components/admin/templates/ProcessingStates.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/template/aas/:aasTemplateHash/:revision?',
    name: 'aasTemplateView',
    component: AASTemplateView,
    props: true
  },
  {
    path: '/templates/aas/browse',
    name: 'aasTemplateList',
    component: AASTemplateList
  },
  {
    path: '/templates/mytemplates',
    name: 'myTemplates',
    component: MyTemplateList
  },
  {
    path: '/templates/upload/:aasTemplateHash?',
    name: 'uploadAASTemplate',
    component: UploadAASTemplate,
    props: true
  },
  {
    path: '/admin/user/groups',
    name: 'groupManagement',
    component: GroupsManagement,
    props: { platform: false }
  },
  {
    path: '/admin/platform/users',
    name: 'usersManagement',
    component: UsersManagement
  },
  {
    path: '/admin/platform/groups',
    name: 'groupsManagement',
    component: GroupsManagement,
    props: { platform: true }
  },
  {
    path: '/admin/platform/processing-states',
    name: 'processingStates',
    component: ProcessingStates
  },
  {
    path: '/invite',
    name: 'invite',
    component: Onboard,
    props: route => ({ token: route.query.join_token })
  },
  {
    path: '/licenses',
    name: 'licenses',
    component: LicensesView
  },
  {
    path: '/about',
    name: 'about',
    component: About
  }

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
