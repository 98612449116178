import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Identifier } from '@aas-dashboard/i40-aas/models'
import { Vue, Watch } from 'vue-property-decorator'
import { Configuration } from '@aas-dashboard/repo-api/configuration'
import store from '@/store'
import _ from 'lodash'
import { DEFAULT_BACKEND_OPENAPI_URL } from '@/constants'
import wsUpdateClient from '@/store/modules/ws-update-client'
import { AASTemplateDescriptor } from '@aas-dashboard/repo-api/models'
import { AASTemplateHash } from '@aas-dashboard/misc/types'
import { DescriptorsApi } from '@aas-dashboard/repo-api'
import { TOPIC_AAS_TEMPLATE_DESCRIPTORS } from '@aas-dashboard/asyncapi/services/constants'
import { BASE_PATH } from '@aas-dashboard/repo-api/base'
import { axiosInstance } from '@/axios'
import AuthStore from '@/store/modules/AuthStore'
import RoleStore from '@/store/modules/RoleStore'

export interface IDescriptorStore {
  aasDescriptors: { [key: string]: AASTemplateDescriptor };
}

@Module({ namespaced: true, store: store, name: 'descriptorstore', dynamic: true })
class DescriptorStore extends VuexModule implements IDescriptorStore {
  private apiService: DescriptorsApi | null = null

  aasDescriptors: { [key: string]: AASTemplateDescriptor } = {}

  @Mutation
  private setupAPIService (): void {
    this.apiService = new DescriptorsApi(new Configuration({
      basePath: DEFAULT_BACKEND_OPENAPI_URL
    }), BASE_PATH, axiosInstance)
  }

  @Mutation
  private addOrUpdateAASDescriptor (descriptor: AASTemplateDescriptor): void {
    Vue.set(this.aasDescriptors, descriptor.hash, descriptor)
  }

  @Mutation
  private removeAASDescriptor (hash: string): void {
    Vue.delete(this.aasDescriptors, hash)
  }

  get getAASDescriptorByHash () {
    return (templateHash: AASTemplateHash): AASTemplateDescriptor | null => {
      return this.aasDescriptors[templateHash] ?? null
    }
  }

  // WARNING: Identifiers were not always unique
  get getAASDescriptorByIdentification () {
    return (identification: Identifier): AASTemplateDescriptor | null => {
      return _.find(this.aasDescriptors, (disc: AASTemplateDescriptor) => _.isEqual(disc.identifier, identification)) ?? null
    }
  }

  @Action({ rawError: true })
  async updateAASDescriptorByHash (descriptorHash: AASTemplateHash) {
    if (this.apiService === null) this.setupAPIService()
    const newDescriptor = (await this.apiService?.retrieveTemplateDescriptor(descriptorHash))?.data
    if (newDescriptor && newDescriptor.hash === descriptorHash) {
      this.addOrUpdateAASDescriptor(newDescriptor)
    } else {
      this.removeAASDescriptor(descriptorHash)
    }
  }

  @Action({ rawError: true })
  async bulkUpdateAASDescriptors () {
    if (!AuthStore.currentUserInfo?.id) return

    if (this.apiService === null) this.setupAPIService()
    let newDescriptors
    try {
      newDescriptors = (await this.apiService?.retrieveAllTemplateDescriptors(AuthStore.currentUserInfo?.id))?.data
    } catch (e) {
      console.log('Failed to retrieve AAS descriptors')
    }
    if (newDescriptors) {
      const newHashes = newDescriptors.map((descriptor) => descriptor.hash)
      // Remove deleted aases
      for (const currentDescriptorHash of Object.keys(this.aasDescriptors)) {
        if (newHashes.find((hash) => hash === currentDescriptorHash) === undefined) {
          this.removeAASDescriptor(currentDescriptorHash)
        }
      }
      // Add or update new aases
      for (const newDescriptor of newDescriptors) {
        const currentDescriptor = this.aasDescriptors[newDescriptor.hash]
        if (currentDescriptor === undefined) {
          this.addOrUpdateAASDescriptor(newDescriptor)
        } else if (!_.isEqual(currentDescriptor, newDescriptor)) {
          this.addOrUpdateAASDescriptor(newDescriptor)
        }
      }
    }
  }

  @Action
  async setupAASTemplateDescriptorsMonitoring (initial = false) {
    if (initial) {
      await this.bulkUpdateAASDescriptors()
    }
    await wsUpdateClient.trackAASTemplateDescriptors()
    wsUpdateClient.on(TOPIC_AAS_TEMPLATE_DESCRIPTORS, async () => {
      await this.bulkUpdateAASDescriptors()
      await RoleStore.updateMyState()
    })
  }
}

export default getModule(DescriptorStore)
