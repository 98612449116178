import {AsyncUpdateClient} from "./async-update-client";
import {
  AASTemplateDescriptorSubscriptionRequest,
  AASTemplateSpecifier,
  AASTemplateSubscriptionRequest,
  ConceptDescriptionsSubscriptionRequest
} from "../models";
import { getLogger } from '@aas-dashboard/misc/logger'
import {
  SUBSCRIBE,
  TOPIC_AAS_TEMPLATES, TOPIC_AAS_TEMPLATE_DESCRIPTORS,
  UNSUBSCRIBE, TOPIC_CONCEPT_DESCRIPTIONS
} from "./constants";
import {Configuration} from "./configuration";

const LOG = getLogger('RepoAsyncUpdateClient')

export class RepoAsyncUpdateClient extends AsyncUpdateClient {

  private trackingAASTemplateDescriptors = false
  private trackedAASTemplates: { [hash: string]: AASTemplateSpecifier } = {}
  private trackingConceptDescriptions = false

  private topics: Set<string> = new Set<string>([TOPIC_AAS_TEMPLATE_DESCRIPTORS, TOPIC_AAS_TEMPLATES, TOPIC_CONCEPT_DESCRIPTIONS])

  constructor (config: Configuration) {
    super(config)
  }

  protected handleMessage (message: { topic: string } & unknown): void {
    if (message.topic) {
      if (this.topics.has(message.topic)) {
        this.emit(message.topic, message)
      } else {
        LOG.warn('Unsupported topic', message.topic)
        return
      }
    } else {
      LOG.warn('Message has no topic, ignoring', message)
    }
  }

  public async trackAASTemplateDescriptors (): Promise<void> {
    await this.send(<AASTemplateDescriptorSubscriptionRequest>{
      topic: TOPIC_AAS_TEMPLATE_DESCRIPTORS,
      action: SUBSCRIBE
    })
    this.trackingAASTemplateDescriptors = true
    LOG.debug('Tracking AASTemplateDescriptors!')
  }

  public async untrackAASTemplateDescriptors (): Promise<void> {
    if (this.trackingAASTemplateDescriptors) {
      await this.send(<AASTemplateDescriptorSubscriptionRequest> {
        topic: TOPIC_AAS_TEMPLATE_DESCRIPTORS,
        action: UNSUBSCRIBE
      })
      this.trackingAASTemplateDescriptors = false
    }
    LOG.debug('No longer tracking AASTemplateDescriptors!')
  }

  public async trackAASTemplates (...aases: AASTemplateSpecifier[]): Promise<void> {
    await this.send(<AASTemplateSubscriptionRequest>{
      topic: TOPIC_AAS_TEMPLATES,
      action: SUBSCRIBE,
      aases
    })
    for (const aas of aases) {
      this.trackedAASTemplates[aas.hash] = aas
    }
  }

  public async untrackAASTemplates (...aases: AASTemplateSpecifier[]): Promise<void> {
    // TODO only request untracking registered aases
    await this.send(<AASTemplateSubscriptionRequest> {
      topic: TOPIC_AAS_TEMPLATES,
      action: UNSUBSCRIBE,
      aases
    })
    for (const aas of aases) {
      delete this.trackedAASTemplates[aas.hash]
    }
  }

  public async trackConceptDescriptions (): Promise<void> {
    await this.send(<ConceptDescriptionsSubscriptionRequest>{
      topic: TOPIC_CONCEPT_DESCRIPTIONS,
      action: SUBSCRIBE
    })
    this.trackingConceptDescriptions = true
    LOG.debug('Tracking Concept Descriptions!')
  }

  public async untrackConceptDescriptions (): Promise<void> {
    if (this.trackingConceptDescriptions) {
      await this.send(<ConceptDescriptionsSubscriptionRequest> {
        topic: TOPIC_CONCEPT_DESCRIPTIONS,
        action: UNSUBSCRIBE
      })
      this.trackingConceptDescriptions = false
    }
    LOG.debug('No longer tracking Concept Descriptions!')
  }
}
