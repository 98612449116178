







import { Component } from 'vue-property-decorator'
import { isReference } from '@aas-dashboard/misc/guards/i40-models'
import BaseView from './BaseView'
import ReferenceView from './ReferenceView.vue'
import {
  Reference,
  ReferenceElement
} from '@aas-dashboard/i40-aas/models'

@Component({
  components: { ReferenceView }
})
export default class ReferenceElementView extends BaseView<ReferenceElement> {
  get getReference (): Reference | null {
    if (isReference(this.submodelElement.value)) {
      return this.submodelElement.value ?? null
    }
    return null
  }
}
