
















import { Component, Vue, Watch } from 'vue-property-decorator'
import AlertStore, { Alert, AlertLvl } from '@/store/modules/AlertStore'

@Component({
  components: { }
})

export default class AlertSnackbar extends Vue {
  private show = false
  private color = ''

  private alert: Alert = new Alert(AlertLvl.INFO, 'default')

  get queue (): Array<Alert> {
    return AlertStore.queue
  }

  @Watch('queue')
  public async queueUpdated (): Promise<void> {
    const alert: Alert | undefined = AlertStore.peek
    if (alert) {
      this.alert = alert
      switch (this.alert.level) {
        case AlertLvl.INFO:
          this.color = 'blue'
          break
        case AlertLvl.WARNING:
          this.color = 'yellow'
          break
        case AlertLvl.ERROR:
          this.color = 'red'
          break
      }
      this.show = true
    } else {
      this.show = false
    }
  }

  @Watch('show')
  public showChanged (): void {
    if (!this.show) {
      setTimeout(AlertStore.popAlert, 100)
    }
  }

  public reloadPage (): void {
    location.reload()
  }
}
