





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ConceptDescription, SubmodelElement } from '@aas-dashboard/i40-aas/models'
import SubmodelElementView from './view/SubmodelElementView.vue'
import { AASHash } from '@aas-dashboard/misc/types'
import { referenceToStr } from '@aas-dashboard/misc/utils/i40-strings'
import {
  IConceptDescriptionStore
} from '@aas-dashboard/frontend-components/store/IConceptDescriptionStore'
import ConceptDescriptionView from './view/ConceptDescriptionView.vue'
import Truncate from '../view/Truncate.vue'
import SemanticTooltip from './SemanticTooltip.vue'

@Component({
  components: { SemanticTooltip, ConceptDescriptionView, SubmodelElementView, Truncate }
})
export default class SingularListItem extends Vue {
  @Prop() readonly aasHash!: AASHash
  @Prop() readonly submodelId!: string
  @Prop() readonly sePath!: string
  @Prop() readonly submodelElement!: SubmodelElement
  @Prop() readonly showValues!: boolean
  @Prop() readonly showConceptDescriptions!: boolean
  @Prop() readonly conceptDescriptionStore!: IConceptDescriptionStore

  public get semanticIdStr (): string | '<unknown>' {
    return referenceToStr(this.submodelElement.semanticId, false)
  }

  public get conceptDescription (): ConceptDescription | null {
    return this.conceptDescriptionStore.getConceptDescriptionByReference(this.submodelElement.semanticId)
  }
}
