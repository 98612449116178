














































import { Component } from 'vue-property-decorator'
import BaseView from './BaseView'
import RelationshipElementView from './RelationshipElementView.vue'
import PropertyView from './PropertyView.vue'
import RangeView from './RangeView.vue'
import ReferenceElementView from './ReferenceElementView.vue'
import FileView from './FileView.vue'
import BlobView from './BlobView.vue'
import MultiLanguagePropertyView from './MultiLanguagePropertyView.vue'
import CollectionListItem from '../CollectionListItem.vue'
import { SubmodelElement } from '@aas-dashboard/i40-aas/models'

@Component({
  name: 'submodel-element-view',
  components: {
    MultiLanguagePropertyView,
    ReferenceElementView,
    RangeView,
    PropertyView,
    RelationshipElementView,
    FileView,
    BlobView,
    'collection-list-item': CollectionListItem,
  }
})
export default class SubmodelElementView extends BaseView<SubmodelElement> { }
