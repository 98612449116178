// tslint:disable
/**
 * Asset Administration Shell REST-API
 * This is going to be the official Asset Administration Shell REST-API as part of Details of the Asset Administration Shell Part 2
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum ModelTypes {
    Asset = 'Asset',
    AssetAdministrationShell = 'AssetAdministrationShell',
    ConceptDescription = 'ConceptDescription',
    Submodel = 'Submodel',
    AccessPermissionRule = 'AccessPermissionRule',
    AnnotatedRelationshipElement = 'AnnotatedRelationshipElement',
    BasicEvent = 'BasicEvent',
    Blob = 'Blob',
    Capability = 'Capability',
    ConceptDictionary = 'ConceptDictionary',
    DataElement = 'DataElement',
    File = 'File',
    Entity = 'Entity',
    Event = 'Event',
    MultiLanguageProperty = 'MultiLanguageProperty',
    Operation = 'Operation',
    Property = 'Property',
    Range = 'Range',
    ReferenceElement = 'ReferenceElement',
    RelationshipElement = 'RelationshipElement',
    SubmodelElement = 'SubmodelElement',
    SubmodelElementCollection = 'SubmodelElementCollection',
    View = 'View',
    GlobalReference = 'GlobalReference',
    FragmentReference = 'FragmentReference',
    Constraint = 'Constraint',
    Formula = 'Formula',
    Qualifier = 'Qualifier'
}



