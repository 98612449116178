
















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Role } from '@aas-dashboard/repo-api/models'
import { platformRoles, UserWithPlatformRole } from '@/components/admin/models'

@Component
export default class UserEditModal extends Vue {
  @Prop() readonly dialog!: boolean
  @Prop() readonly mayModifyRole!: boolean // TODO: maybe not needed
  @Prop() public readonly user: UserWithPlatformRole | null = null

  public readonly roleItems = platformRoles

  public newUser: UserWithPlatformRole = {
    id: '',
    displayName: '',
    email: '',
    platformRole: Role.Anonymous
  }

  @Watch('user')
  public onUserPropChange (): void {
    if (this.user) {
      this.newUser = {
        ...this.user
      }
    }
  }
}
