




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { SubmodelElement } from '@aas-dashboard/i40-aas/models'
import SingularListItem from './SingularListItem.vue'
import { AASHash } from '@aas-dashboard/misc/types'
import { isSubmodelElementCollection, isOperation } from '@aas-dashboard/misc/guards/i40-models'
import { referenceToStr } from '@aas-dashboard/misc/utils/i40-strings'
import { IConceptDescriptionStore } from '@aas-dashboard/frontend-components/store/IConceptDescriptionStore'
import { conceptDescriptionToShort } from './helpers'
import ConceptDescriptionTooltip from './ConceptDescriptionTooltip.vue'
import OperationList from './OperationList.vue'
import SemanticTooltip from './SemanticTooltip.vue'
@Component({
  name: 'collection-list-item',
  components: { SingularListItem, SemanticTooltip, OperationList }
})
export default class CollectionListItem extends Vue {
  @Prop() readonly aasHash!: AASHash
  @Prop() readonly submodelId!: string
  @Prop() readonly submodelElement!: SubmodelElement
  @Prop() readonly sePath!: string
  @Prop() readonly showValues!: boolean
  @Prop() readonly showConceptDescriptions!: boolean
  @Prop() readonly conceptDescriptionStore!: IConceptDescriptionStore

  private panels = []

  public isCollection (se: SubmodelElement): boolean {
    return isSubmodelElementCollection(se)
  }

  public isAnOperation (se: SubmodelElement): boolean {
    return isOperation(se)
  }

  public get semanticId (): string | '<unknown>' {
    return referenceToStr(this.submodelElement.semanticId, false)
  }

  public get conceptDescription (): string | null {
    return conceptDescriptionToShort(this.conceptDescriptionStore.getConceptDescriptionByReference(this.submodelElement.semanticId))
  }

  public beforeCreate (): void {
    if (this !== undefined && this.$options !== undefined && this.$options.components !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      this.$options.components.SingularListItem = require('./SingularListItem').default
    }
  }
}
