import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import {
  ConceptDescription,
  DataSpecificationIEC61360Content,
  Identifier,
  Reference
} from '@aas-dashboard/i40-aas/models'
import { Vue } from 'vue-property-decorator'
import { ConceptDescriptionsApi } from '@aas-dashboard/api'
import { Configuration } from '@aas-dashboard/api/configuration'
import store from '@/store'
import { DEFAULT_BACKEND_OPENAPI_URL } from '@/constants'
import wsUpdateClient from '@/store/modules/ws-update-client'
import {
  TOPIC_CONCEPT_DESCRIPTIONS
} from '@aas-dashboard/asyncapi/services/constants'
import { identifierToStr } from '@aas-dashboard/misc/utils/i40-strings'
import {
  ConceptDescriptionForLanguage,
  IConceptDescriptionStore
} from '@aas-dashboard/frontend-components/store/IConceptDescriptionStore'

//
// WARNING: This file is duplicated in frontend-repo and frontend-rd
// Because it seems stores cannot be shared via frontend-components!
//

function getKey (keys: Array<string>, key: string): string | null {
  if (keys.length === 0) return null

  key = key.toLowerCase()
  let nameKey = keys.find(name => name.toLowerCase() === key)
  if (!nameKey && key !== 'en') {
    nameKey = keys.find(name => name.toLowerCase() === 'en')
  }
  if (!nameKey) {
    nameKey = keys[0]
  }
  return nameKey
}

@Module({ namespaced: true, store: store, name: 'conceptdescriptionstore', dynamic: true })
class ConceptDescriptionStore extends VuexModule implements IConceptDescriptionStore {
  private conceptDescriptionsService: ConceptDescriptionsApi | null = null

  conceptDescriptions: { [key: string]: ConceptDescription } = {}

  @Mutation
  private setupConceptDescriptionsService (): void {
    this.conceptDescriptionsService = new ConceptDescriptionsApi(new Configuration({
      basePath: DEFAULT_BACKEND_OPENAPI_URL
    }))
  }

  @Mutation
  private upsertConceptDescription (cd: ConceptDescription): void {
    Vue.set(this.conceptDescriptions, identifierToStr(cd.identification).toLowerCase(), cd)
  }

  get getConceptDescriptorByIdentifier () {
    return (identifier: Identifier): ConceptDescription | null => {
      return this.conceptDescriptions[identifierToStr(identifier).toLowerCase()]
    }
  }

  get getConceptDescriptionByReference () {
    return (reference: Reference): ConceptDescription | null => {
      const firstKey = reference?.keys?.[0]
      if (!firstKey) return null

      const identifierStr = (firstKey.idType + ' : ' + firstKey.value).toLowerCase()
      return this.conceptDescriptions[identifierStr]
    }
  }

  get getConceptDescriptionForLanguageByReference () {
    return ({ reference, language } : { reference: Reference, language: string }): ConceptDescriptionForLanguage | null => {
      const cd: ConceptDescription | null = this.getConceptDescriptionByReference(reference)
      if (!cd) return null

      const preferredNames: { [key: string]: string } = {}
      for (const spec of cd.embeddedDataSpecifications ?? []) {
        for (const prefName of (spec.dataSpecificationContent as DataSpecificationIEC61360Content).preferredName) {
          preferredNames[prefName.language.toLowerCase()] = prefName.text
        }
      }
      if (Object.keys(preferredNames).length === 0) {
        return null
      }

      const definitions: { [key: string]: string } = {}
      for (const spec of cd.embeddedDataSpecifications ?? []) {
        for (const definition of spec.dataSpecificationContent.definition ?? []) {
          definitions[definition.language.toLowerCase()] = definition.text
        }
      }

      const preferredKey = getKey(Object.keys(preferredNames), language)
      const definitionKey = getKey(Object.keys(definitions), language)
      const cdForLanguage: ConceptDescriptionForLanguage = {
        identification: cd.identification,
        preferredName: preferredKey ? preferredNames[preferredKey] : null,
        definition: definitionKey ? definitions[definitionKey] : null
      }

      return cdForLanguage
    }
  }

  @Action({ rawError: true })
  async bulkUpdateConceptDescriptions () {
    if (this.conceptDescriptionsService === null) this.setupConceptDescriptionsService()
    const cds = (await this.conceptDescriptionsService!.getAllConceptDescriptions()).data
    for (const conceptDescription of cds) {
      this.upsertConceptDescription(conceptDescription)
    }
  }

  @Action
  async setupConceptDescriptionsMonitoring (initial = false) {
    if (initial) {
      await this.bulkUpdateConceptDescriptions()
    }
    await wsUpdateClient.trackConceptDescriptions()
    wsUpdateClient.on(TOPIC_CONCEPT_DESCRIPTIONS, async () => {
      await this.bulkUpdateConceptDescriptions()
    })
  }
}

export default getModule(ConceptDescriptionStore)
