














































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LicensesView extends Vue {
  private licenses: Record<string, Record<string, { licenseText?: string }>> = {}

  get usedLicenses (): Record<string, Record<string, { licenseText?: string }>> {
    return this.licenses
  }

  public hasLicenseText (licenseName: string, dependency: string): boolean {
    return !!this.licenses?.[licenseName]?.[dependency]?.licenseText
  }

  async mounted (): Promise<void> {
    // Lazy load licenses
    this.licenses = (await import('../generated/licenses.json')).default
  }
}
