import './class-component-hooks'
import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import store from './store'
import router from '@/router/router'
import VuetifyConfirm from 'vuetify-confirm'
import '../public/global.css'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.config.devtools = true

Vue.use(VuetifyConfirm, {
  vuetify
})

Vue.use(VueMeta)

new Vue({
  vuetify,
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
