import { Role, User } from '@aas-dashboard/repo-api/models'

export type UserWithPlatformRole = User & {
  platformRole: Role
}

export const templateRoles = [Role.Guest, Role.Member, Role.Editor]

export const groupRoles = [Role.Member, Role.Editor, Role.GroupAdmin]

export const platformRoles = [Role.Anonymous, Role.Guest, Role.Member, Role.PlatformAdmin]
