/* tslint:disable */
/* eslint-disable */
/**
 * AAS Template repository API
 * The API implemented by the AAS repository
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum Role {
    Anonymous = 'Anonymous',
    Guest = 'Guest',
    Member = 'Member',
    Editor = 'Editor',
    GroupAdmin = 'GroupAdmin',
    PlatformAdmin = 'PlatformAdmin'
}



