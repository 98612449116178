






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Operation, OperationVariable } from '@aas-dashboard/i40-aas/models'
import SingularListItem from './SingularListItem.vue'
import BaseView from './view/BaseView'

@Component({
  components: { SingularListItem, BaseView }
})
export default class OperationInputOutputList extends BaseView<Operation> {
  @Prop() readonly sePath!: string
  @Prop() readonly variableList!: Array<OperationVariable>
  @Prop() readonly headerText!: string
  @Prop() readonly noElementsText!: string
}
