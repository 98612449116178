
export const SUBSCRIBE = 'subscribe'
export const UNSUBSCRIBE = 'unsubscribe'

// RD topics
export const TOPIC_UI_SETTINGS = 'ui-settings'
export const TOPIC_SERVERS = 'servers'
export const TOPIC_DASHBOARDS = 'dashboards'
export const TOPIC_AAS_DESCRIPTORS = 'aas-descriptors'
export const TOPIC_AASES = 'aases'
export const TOPIC_ASSETS = 'assets'
export const TOPIC_SUBMODELS = 'submodels'
export const TOPIC_SUBMODEL_ELEMENTS = 'submodel-elements'

// Template repo topics
export const TOPIC_AAS_TEMPLATE_DESCRIPTORS = 'aas-template-descriptors'
export const TOPIC_AAS_TEMPLATES = 'aas-templates'

// Shared topics
export const TOPIC_CONCEPT_DESCRIPTIONS = 'concept-descriptions'
