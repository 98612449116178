












































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Asset, AssetAdministrationShell, Submodel } from '@aas-dashboard/i40-aas/models'
import AASTemplateStore from '@/store/modules/AASTemplateStore'
import DescriptorStore from '@/store/modules/DescriptorStore'
import ConceptDescriptionStore from '@/store/modules/ConceptDescriptionStore'
import { ViewJSONDialog, SubmodelPanel } from '@aas-dashboard/frontend-components/aas'
import { AASTemplateHash } from '@aas-dashboard/misc/types'
import { AASTemplateDescriptor } from '@aas-dashboard/repo-api/models'
import MetadataPanel from '@/components/template/aas/MetadataPanel.vue'
import AlertStore, { Alert, AlertLvl } from '@/store/modules/AlertStore'
import RoleStore from '@/store/modules/RoleStore'
import { Role } from '@aas-dashboard/repo-api'
import { AAS_TEMPLATE_REPO_TITLE } from '@/constants'
import JsZip from 'jszip'

export type AASRESTIdentifier = { aasURL: string; aasId: string }

@Component({
  components: {
    SubmodelPanel,
    MetadataPanel,
    'view-json-dialog': ViewJSONDialog
  }
})
export default class AASTemplateView extends Vue {
  @Prop() readonly aasTemplateHash!: AASTemplateHash
  @Prop() readonly revision!: string | undefined

  AASTemplateStore: typeof AASTemplateStore = AASTemplateStore

  public metadata = 'metadata'
  public data = 'data'
  public conceptDescriptions = 'concept-descriptions'

  private jsonDialog = false
  private jsonData: Asset | AssetAdministrationShell | Submodel | null = null

  private showToggles = [this.metadata]
  private showTogglesExclusive = []

  public metaInfo () {
    return { title: `${this.aas?.idShort} | ${AAS_TEMPLATE_REPO_TITLE}` }
  }

  get aas (): AssetAdministrationShell | null {
    if (this.aasTemplateHash) {
      return AASTemplateStore.aases[this.aasTemplateHash]
    }
    return null
  }

  get submodelIds (): string[] {
    if (this.aasTemplateHash) {
      return AASTemplateStore.getSubmodelsIdShort(this.aasTemplateHash)
    }
    return []
  }

  get submodels (): Submodel[] | null {
    if (this.submodelIds) {
      return this.submodelIds.map((idShort: string) => AASTemplateStore.getSubmodel(this.aasTemplateHash, idShort) || {} as Submodel)
    }
    return null
  }

  get aasTemplateDescriptor (): AASTemplateDescriptor | null {
    if (this.aasTemplateHash) {
      return DescriptorStore.getAASDescriptorByHash(this.aasTemplateHash) ?? null
    }
    return null
  }

  get revisionProcessed () : string {
    return this.revision ?? (this.aasTemplateDescriptor?.revisions[this.aasTemplateDescriptor?.revisions.length - 1] ?? 'undefined')
  }

  private async loadAASView () {
    if (this.aasTemplateHash) {
      await AASTemplateStore.loadAAS(this.aasTemplateHash)
    }
  }

  get aasDescriptors (): { [key: string]: AASTemplateDescriptor } {
    return DescriptorStore.aasDescriptors
  }

  get conceptDescriptionStore (): typeof ConceptDescriptionStore {
    return ConceptDescriptionStore
  }

  private showJson (data: Asset | AssetAdministrationShell | Submodel) {
    this.jsonDialog = true
    this.jsonData = data
  }

  private binaryUrlTemplate (aasTemplateHash: AASTemplateHash, submodelId: string, sePath: string): string | null {
    // return `${DEFAULT_BACKEND_OPENAPI_URL}/cache/aas/${aasHash}/submodels/${submodelId}/${sePath}`
    return null
  }

  public async downloadAASX (): Promise<void> {
    const data = await AASTemplateStore.downloadTemplateAASX({ hash: this.aasTemplateHash, revision: this.revision })
    const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/zip' }))
    const fileLink = document.createElement('a')

    const descriptor = this.aasTemplateDescriptor
    if (!descriptor) {
      const message = 'Template descriptor missing on server'
      AlertStore.pushAlert(new Alert(
        AlertLvl.ERROR,
        message
      ))
      throw new Error(message)
    }

    fileLink.href = fileURL
    fileLink.setAttribute('style', 'display: none')
    fileLink.setAttribute('download', `${descriptor.idShort}_${this.revisionProcessed}.aasx`)
    document.body.appendChild(fileLink)

    fileLink.click()
  }

  public async downloadJSON (): Promise<void> {
    if (!this.submodels || !this.aasTemplateDescriptor || this.submodels.length === 0) return

    const fileLink = document.createElement('a')
    fileLink.setAttribute('style', 'display: none')

    if (this.submodels?.length === 1) {
      fileLink.href = window.URL.createObjectURL(new Blob([JSON.stringify(this.submodels[0], null, 2)], { type: 'text/plain;charset=utf-8' }))
      fileLink.setAttribute('download', `${this.aasTemplateDescriptor.idShort}_${this.revisionProcessed}.json`)
    } else {
      const zip = JsZip()
      for (const submodel of this.submodels) {
        zip.file(`${submodel.idShort}.json`, JSON.stringify(submodel, null, 2))
      }
      fileLink.href = window.URL.createObjectURL(new Blob([await zip.generateAsync({ type: 'blob' })], { type: 'application/zip' }))
      fileLink.setAttribute('download', `${this.aasTemplateDescriptor.idShort}_${this.revisionProcessed}.zip`)
    }

    document.body.appendChild(fileLink)
    fileLink.click()
  }

  public async uploadNewAASX (): Promise<void> {
    const descriptor = this.aasTemplateDescriptor
    if (!descriptor) {
      const message = 'Template descriptor missing on server, unsure what to upload'
      AlertStore.pushAlert(new Alert(
        AlertLvl.ERROR,
        message
      ))
      throw new Error(message)
    }

    await this.$router.push({ name: 'uploadAASTemplate', params: { aasTemplateHash: this.aasTemplateHash } })
  }

  get canEdit (): boolean {
    const descriptor = this.aasTemplateDescriptor
    if (!descriptor) return false

    return RoleStore.myTemplateRoleBindings[descriptor.hash]?.role === Role.Editor
  }

  @Watch('aasDescriptors')
  public async aasHashesChanged (_: { [key: string]: AASRESTIdentifier }): Promise<void> {
    await this.loadAASView()
  }

  @Watch('$route')
  public async routeUpdate (): Promise<void> {
    await this.loadAASView()
  }

  public async mounted (): Promise<void> {
    await this.loadAASView()
  }
}
