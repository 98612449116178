// tslint:disable
/**
 * Asset Administration Shell REST-API
 * This is going to be the official Asset Administration Shell REST-API as part of Details of the Asset Administration Shell Part 2
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { LangString } from './lang-string';
import { LevelType } from './level-type';
import { Reference } from './reference';
import { ValueList } from './value-list';

/**
 * 
 * @export
 * @interface DataSpecificationIEC61360ContentAllOf
 */
export interface DataSpecificationIEC61360ContentAllOf {
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    dataType?: DataSpecificationIEC61360ContentAllOfDataTypeEnum;
    /**
     * 
     * @type {Array<LangString>}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    definition?: Array<LangString>;
    /**
     * 
     * @type {Array<LangString>}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    preferredName: Array<LangString>;
    /**
     * 
     * @type {Array<LangString>}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    shortName?: Array<LangString>;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    sourceOfDefinition?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    symbol?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    unit?: string;
    /**
     * 
     * @type {Reference}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    unitId?: Reference;
    /**
     * 
     * @type {string}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    valueFormat?: string;
    /**
     * 
     * @type {ValueList}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    valueList?: ValueList;
    /**
     * 
     * @type {Array<LevelType>}
     * @memberof DataSpecificationIEC61360ContentAllOf
     */
    levelType?: Array<LevelType>;
}

/**
    * @export
    * @enum {string}
    */
export enum DataSpecificationIEC61360ContentAllOfDataTypeEnum {
    DATE = 'DATE',
    STRING = 'STRING',
    STRINGTRANSLATABLE = 'STRING_TRANSLATABLE',
    REALMEASURE = 'REAL_MEASURE',
    REALCOUNT = 'REAL_COUNT',
    REALCURRENCY = 'REAL_CURRENCY',
    BOOLEAN = 'BOOLEAN',
    URL = 'URL',
    RATIONAL = 'RATIONAL',
    RATIONALMEASURE = 'RATIONAL_MEASURE',
    TIME = 'TIME',
    TIMESTAMP = 'TIMESTAMP'
}



