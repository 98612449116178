







import { Component } from 'vue-property-decorator'
import BaseView from './BaseView'
import { Property } from '@aas-dashboard/i40-aas/models'
import Truncate from '../../view/Truncate.vue'

@Component({ components: { Truncate } })
export default class PropertyView extends BaseView<Property> { }
