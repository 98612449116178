import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Configuration } from '@aas-dashboard/repo-api/configuration'
import store from '@/store'
import { DEFAULT_BACKEND_OPENAPI_URL } from '@/constants'
import { AccessApi } from '@aas-dashboard/repo-api'
import { BearerSpec, BearerType, ResourceSpec, ResourceType, Role, RoleBinding, User } from '@aas-dashboard/repo-api/models'
import { Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { BASE_PATH } from '@aas-dashboard/repo-api/base'
import { axiosInstance } from '@/axios'

function rolebindingToStr (rolebinding: RoleBinding) {
  if (rolebinding.resource.resourceType === ResourceType.Platform) {
    return `${rolebinding.resource.resourceType}|${rolebinding.bearer.bearerId}|${rolebinding.bearer.bearerType}`
  } else {
    return `${rolebinding.resource.resourceId}|${rolebinding.resource.resourceType}|${rolebinding.bearer.bearerId}|${rolebinding.bearer.bearerType}`
  }
}

class RolesCollection {
  // Linter bug?
  // eslint-disable-next-line no-useless-constructor
  constructor (private _roles: RoleBinding[] = []) {}

  get roles () {
    return [...this._roles]
  }

  public filterByResource (resourceSpec: ResourceSpec | { resourceType: ResourceType.Platform}) {
    return new RolesCollection(
      this._roles.filter(rb =>
        rb.resource.resourceType === resourceSpec.resourceType &&
        (resourceSpec.resourceType === ResourceType.Platform || resourceSpec.resourceId === rb.resource.resourceId)
      )
    )
  }

  public filterByBearer (bearerSpec: BearerSpec) {
    return new RolesCollection(
      this._roles.filter(rb =>
        _.isEqual(rb.bearer, bearerSpec)
      )
    )
  }

  // TODO optimize overwriting to O(1) instead of O(n^2) by using dictionaries
  public addRoles (roles: RoleBinding[]) {
    const newRoles: { [k: string]: RoleBinding } = {}
    for (const role of this._roles) {
      newRoles[rolebindingToStr(role)] = role
    }
    for (const role of roles) {
      newRoles[rolebindingToStr(role)] = role
    }
    this._roles = Object.values(newRoles)
  }
}

export interface IUserStore {
  users: { [key: string]: User };
}

@Module({ namespaced: true, store: store, name: 'userstore', dynamic: true })
class UserStore extends VuexModule implements IUserStore {
  public users: { [key: string]: User } = {}

  private roles = new RolesCollection()

  private usersApiService: AccessApi | null = null

  @Mutation
  public setupAPIService (): void {
    this.usersApiService = new AccessApi(new Configuration({
      basePath: DEFAULT_BACKEND_OPENAPI_URL,
      baseOptions: {
        withCredentials: true
      }
    }), BASE_PATH, axiosInstance)
  }

  @Mutation
  public addUser (user: User) {
    Vue.set(this.users, user.id, user)
  }

  @Action({ rawError: true })
  public async getUsers (userIds: string[]): Promise<User[] | null> {
    const cached = _.filter(this.users, (user) => userIds.includes(user.id))
    const cachedIds = cached.map((user) => user.id)
    const toRetrieve = _.filter(userIds, (id) => !cachedIds.includes(id))

    if (toRetrieve.length !== 0) {
      if (this.usersApiService === null) this.setupAPIService()
      let users: User[] | undefined
      try {
        users = (await this.usersApiService?.retrieveUsers(undefined, undefined, userIds))?.data ?? []
      } catch (e) {
        console.warn('Failed to retrieve Users', toRetrieve)
      }

      if (users && users.length !== 0) {
        for (const user of users) {
          this.addUser(user)
        }
        cached.push(...users)
      }
    }
    return cached
  }

  @Action({ rawError: true })
  public async getAllUsers (): Promise<User[] | null> {
    // WARNING: This requires USER_VIEW_ALL
    if (this.usersApiService === null) this.setupAPIService()
    let users: User[] | null = null
    try {
      users = (await this.usersApiService?.retrieveUsers(undefined, undefined, undefined))?.data ?? []
    } catch (e) {
      console.warn('Failed to retrieve ALL Users')
    }

    if (users && users.length !== 0) {
      for (const user of users) {
        this.addUser(user)
      }
    }
    return users
  }

  @Action({ rawError: true })
  public async getUser (userId: string): Promise<User | null> {
    const users = await this.getUsers([userId])
    return (users && users.length !== 0) ? users[0] : null
  }

  get getPlatformRolebindings () {
    return (): RoleBinding[] => {
      return this.roles.filterByResource({
        resourceType: ResourceType.Platform
      }).roles
    }
  }

  get getPlatformRoleByUserId () {
    return (userId: string): Role => {
      const platformRoles = this.roles.filterByResource({
        resourceType: ResourceType.Platform
      })
      const bearerRoles = platformRoles.filterByBearer(<BearerSpec>{
        bearerType: BearerType.User,
        bearerId: userId
      }).roles
      return bearerRoles[0]?.role ?? Role.Anonymous
    }
  }

  // @Action({ rawError: true })
  // public async updateUser (newUser: User): Promise<User[] | null> {
  //   if (this.usersApiService === null) this.setupAPIService()

  //   let users: User[] | undefined
  //   try {
  //     users = (await this.usersApiService?.i[])?.data
  //   } catch (e) {
  //     console.warn('Failed to retrieve User', id)
  //   }
  //   if (!users) return null

  //   for (const user of users) {
  //     this.addUser(user)
  //   }
  //   return Object.values(this.users)
  // }

  @Action({ rawError: true })
  public async deleteUser (id: string) {
    if (!this.users[id]) {
      console.error(`User with id ${id} does not exist`)
    }
    if (this.usersApiService === null) this.setupAPIService()

    await this.usersApiService?.deleteUser(id)
    // TODO: Just bindings for deleted user
    await this.retrievePlatformRolebindings()
  }

  @Action({ rawError: true })
  public async allowUserOntoPlatform (id: string): Promise<void> {
    await this.setUserPlatformRole({ id, role: Role.Member })
  }

  @Action({ rawError: true })
  public async setUserPlatformRole ({ id, role }: { id: string, role: Role }): Promise<void> {
    if (!this.users[id]) {
      console.error(`User with id ${id} does not exist`)
    }
    if (this.usersApiService === null) this.setupAPIService()

    const newBinding = {
      resource: {
        resourceId: 'platform',
        resourceType: ResourceType.Platform
      },
      bearer: {
        bearerId: id,
        bearerType: BearerType.User
      },
      role
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await this.usersApiService!.modifyUserRoleBinding(id, newBinding)
    this.roles.addRoles([newBinding])
  }

  @Action({ rawError: true })
  public async retrievePlatformRolebindings (): Promise<void> {
    if (this.usersApiService === null) this.setupAPIService()

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const rolebindings = (await this.usersApiService!.retrievePlatformRoleBindings()).data
    this.roles.addRoles(rolebindings)
  }

  @Action({ rawError: true })
  public async findUsersByDisplayName (displayName: string): Promise<User[]> {
    if (this.usersApiService === null) this.setupAPIService()

    let users: User[] = []
    try {
      users = (await this.usersApiService?.retrieveUsers(encodeURIComponent(displayName)))?.data ?? []
    } catch (e) {
      console.warn('Failed to find Users for', displayName)
    }
    return users
  }

  @Action({ rawError: true })
  public async findUserByEmailExact (email: string): Promise<User | undefined> {
    if (this.usersApiService === null) this.setupAPIService()

    email = email.toLowerCase()
    const usr = _.find(this.users, user => user.email?.toLowerCase() === email)

    if (usr) {
      return usr
    } else {
      let users: User[] | undefined
      try {
        users = (await this.usersApiService?.retrieveUsers(email))?.data
      } catch (e) {
        console.warn('Failed to find Users for', email)
      }

      if (users && users.length !== 0) {
        this.addUser(users[0])
        return users[0]
      }

      return undefined
    }
  }
}

export default getModule(UserStore)
