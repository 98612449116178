
























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { DEFAULT_BACKEND_URL } from '@/constants'
import DescriptorStore from '@/store/modules/DescriptorStore'
import { identifierToStr } from '@aas-dashboard/misc/utils/i40-strings'
import { Identifier } from '@aas-dashboard/i40-aas/models/identifier'
import { AASTemplateHash } from '@aas-dashboard/misc/types'
import Truncate from '@aas-dashboard/frontend-components/view/Truncate.vue'

type TemplateDescriptorEntry = {
  hash: AASTemplateHash;
  idShort: string;
  identifierFlat: string;
  description: string;
  authors: string;
  revision: string;
}

@Component({
  components: { Truncate }
})
export default class AASTemplateList extends Vue {
  private DEFAULT_BACKEND_URL = DEFAULT_BACKEND_URL

  private search = ''
  private sortBy = 'idShort'
  private sortDesc = false

  private headers = [
    // { text: 'Icon', value: 'thumbnailUrl', sortable: false, filterable: false },
    { text: 'Short Id', value: 'idShort' },
    { text: 'Identifier', value: 'identifierFlat' },
    { text: 'Description', value: 'description' },
    { text: 'Authors', value: 'authors' },
    { text: 'Revision', value: 'revision' }
  ]

  get templateDescriptors (): TemplateDescriptorEntry[] {
    const result: TemplateDescriptorEntry[] = []

    for (const desc of Object.values(DescriptorStore.aasDescriptors)) {
      result.push({
        hash: desc.hash,
        idShort: desc.idShort,
        identifierFlat: identifierToStr(desc.identifier),
        description: desc.description ?? '',
        authors: desc.authors?.join(',') ?? '<UNKNOWN>',
        revision: desc.revisions.length > 0 ? desc.revisions[desc.revisions.length - 1] : '<UNKWOWN>'
      })
    }
    return result
  }

  private openAASTemplate (value: TemplateDescriptorEntry): void {
    this.$router.push({ name: 'aasTemplateView', params: { aasTemplateHash: value.hash } })
  }
}
