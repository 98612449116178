







import { Component } from 'vue-property-decorator'
import BaseView from './BaseView'
import { MultiLanguageProperty } from '@aas-dashboard/i40-aas/models'
import { unwrapValueToStr } from '@aas-dashboard/misc/utils/i40-strings'
import Truncate from '../../view/Truncate.vue'

@Component({ components: { Truncate } })
export default class MultiLanguagePropertyView extends BaseView<MultiLanguageProperty> {
  get value (): string | null {
    return unwrapValueToStr(this.submodelElement)
  }
}
