
























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { BearerType, Group, ResourceType, Role, RoleBinding } from '@aas-dashboard/repo-api/models'
import RoleStore from '@/store/modules/RoleStore'
import UserStore from '@/store/modules/UserStore'
import { User } from '@aas-dashboard/repo-api'
import { groupRoles } from '@/components/admin/models'
import _ from 'lodash'
import AuthStore from '@/store/modules/AuthStore'
import { MyEventEmitter } from '@/event'
import InviteGroupMemberModal from '@/components/admin/groups/InviteGroupMemberModal.vue'
import GroupLinkModal from '@/components/admin/groups/GroupLinkModal.vue'

interface Member {
  user: User;
  role: Role;
  binding: RoleBinding;
  me: boolean;
}

@Component({
  components: { InviteGroupMemberModal, GroupLinkModal }
})
export default class EditGroupMembersModal extends Vue {
  @Prop() readonly dialog!: boolean
  @Prop() readonly group!: Group
  @Prop() readonly myRoleBinding!: RoleBinding
  @Prop() readonly modalEvents!: MyEventEmitter
  @Prop() readonly platform!: boolean

  Role: typeof Role = Role

  public membersHeader = [
    { text: '', value: 'me' },
    { text: 'Name', value: 'user.displayName' },
    { text: 'Role', value: 'role' },
    { text: '', value: 'controls', sortable: false, align: 'right' }
  ]

  public members: Member[] = []

  public groupUsers: RoleBinding[] = []

  public showInviteMember = false
  public showGroupLinkModal = false

  get roles (): Role[] {
    return groupRoles
  }

  get editable (): boolean {
    return this.platform || (!!this.myRoleBinding && this.myRoleBinding.role === Role.GroupAdmin)
  }

  @Watch('group')
  public async onGroupPropChange (): Promise<void> {
    if (this.group) {
      const roleBindings = await RoleStore.getRoleBindingsGroup(this.group.id) ?? []
      this.groupUsers = roleBindings.filter(binding => binding.resource.resourceType === ResourceType.Group && binding.bearer.bearerType === BearerType.User)
      const groupUserIds = this.groupUsers.map(binding => binding.bearer.bearerId)
      await UserStore.getUsers(groupUserIds)
      const me = AuthStore.currentUserInfo?.id
      this.members = this.groupUsers.map(binding => { return { user: UserStore.users[binding.bearer.bearerId], role: binding.role, binding, me: UserStore.users[binding.bearer.bearerId]?.id === me } })
    }
  }

  public async updateRole (member: Member): Promise<void> {
    const newBinding = _.cloneDeep(member.binding)
    newBinding.role = member.role
    await RoleStore.addRoleBindingToGroup({ groupId: this.group.id, binding: newBinding })
    await this.onGroupPropChange()
  }

  public async deleteMember (member: Member): Promise<void> {
    const confirmation = await this.$confirm(`Are you sure you want to remove ${member.user.displayName} from the group?`, { title: 'Remove user from group?', icon: '' })
    if (!confirmation) return
    await RoleStore.removeRolebindingFromGroup(member.binding)
    await this.onGroupPropChange()
  }

  public onShowInviteGroupMember (): void {
    this.showInviteMember = true
  }

  public onInviteGroupMemberClosed (): void {
    this.showInviteMember = false
    this.modalEvents.emit('refresh-group-bindings')
  }

  public onShowGroupLinks (): void {
    this.showGroupLinkModal = true
  }

  public onGroupLinksClosed (): void {
    this.showGroupLinkModal = false
  }

  public mounted (): void {
    this.modalEvents.on('refresh-group-bindings', this.onGroupPropChange)
  }
}
