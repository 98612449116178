import {
  Identifier,
  Key,
  LangString,
  Reference, SubmodelElement
} from '@aas-dashboard/i40-aas/models'
import { isProperty, isMultiLanguageProperty } from '@aas-dashboard/misc/guards/i40-models'

export function keyToStr (key: Key): string | null {
  if (!key) return null
  return `${key.type} : ${key.idType} : ${key.value}`
}

export function referenceToStrArray (ref: Reference | null, includeType = false): string[] {
  if (!ref) {
    return []
  }
  const res: string[] = []
  for (const key of ref.keys) {
    res.push((includeType ? key.type + ' : ' : '') + key.idType + ': ' + key.value)
  }
  return res
}

export function referenceToStr (ref: Reference | null, includeType = false): string | '<unknown>' {
  const res: string[] = referenceToStrArray(ref, includeType)
  return res.length > 0 ? res.join(';') : '<unknown>'
}

export function identifierToStr (identifier: Identifier | null, includeType = true): string | '<unknown>' {
  if (!identifier) {
    return '<unknown>'
  }
  return (includeType ? identifier.idType + ' : ' : '') + identifier.id
}

export function unwrapValueToStr (se: SubmodelElement | null): string | null {
  if (!se) {
    return null
  } else if (isProperty(se)) {
    return se.value ?? null
  } else if (isMultiLanguageProperty(se)) {
    // WARNING: aasx-server does NOT implement this property correctly! See array vs ['langString']->array
    let langStringSet: LangString[] | null = null
    if (se.value) {
      if (Array.isArray(se.value)) {
        langStringSet = se.value
      } else if ((se.value as { langString: LangString[] }).langString) {
        langStringSet = (se.value as { langString: LangString[] }).langString
      }
    }

    let langString: LangString | undefined
    if (langStringSet) {
      const exactLang = navigator.language
      langString = langStringSet.find(langString => langString.language === exactLang)
      if (!langString) {
        if (exactLang.includes('-')) {
          const lang = exactLang.split('-')[0]
          langString = langStringSet.find(langString => langString.language === lang)
        }
        if (!langString) { // Show something if still no match is found
          langString = langStringSet[0]
        }
      }
    }

    return langString?.text ?? null
  } else {
    throw new Error(`SubmodelElement type not supported: ${se.modelType.name}`)
  }
}
