// FIXME: abstract into some generic module, but for now this one is used by everything
import log, { LogLevelDesc } from 'loglevel'
import chalk from 'chalk'
import prefix from 'loglevel-plugin-prefix'

// Log formatting
const colors: any = {
  TRACE: chalk.magenta,
  DEBUG: chalk.cyan,
  INFO: chalk.blue,
  WARN: chalk.yellow,
  ERROR: chalk.red,
}
prefix.reg(log)
prefix.apply(log, {
  format(level = 'DEBUG', name = 'root', timestamp = new Date()) {
    return `${chalk.gray(`[${timestamp}]`)} ${colors[level.toUpperCase()](level)} ${chalk.green(`${name}:`)}`;
  },
})

const LOGLEVEL = process.env.LOGLEVEL || 'info'

export const getLogger = (name: string = process.env.APP_NAME || 'root', level: string = LOGLEVEL) => {
  log.setLevel(level as LogLevelDesc) // warning: typecast, we should check if equal to one of the allowed values
  return log.getLogger(name)
}
