


















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Role, User } from '@aas-dashboard/repo-api/models'
import UserStore from '@/store/modules/UserStore'
import UserEditModal from './UserEditModal.vue'
import { UserWithPlatformRole } from '@/components/admin/models'

@Component({
  components: { UserEditModal }
})
export default class UsersManagement extends Vue {
  public search = ''
  public sortBy = 'id'
  public sortDesc = false

  public showEditUser = false
  public userToEdit: UserWithPlatformRole | null = null

  public headers = [
    // { text: 'Icon', value: 'thumbnailUrl', sortable: false, filterable: false },
    { text: 'Id', value: 'id' },
    { text: 'Name', value: 'displayName' },
    { text: 'Email', value: 'email' },
    { text: 'Platform Role', value: 'platformRole' },
    { text: '', value: 'controls', sortable: false, align: 'right' }
  ]

  get users (): (User & { platformRole: Role })[] {
    const result = Object.values(UserStore.users).map(user => ({
      ...user,
      platformRole: UserStore.getPlatformRoleByUserId(user.id)
    }))
    return result
  }

  public async mounted (): Promise<void> {
    await Promise.all([
      UserStore.getAllUsers(),
      UserStore.retrievePlatformRolebindings()
    ])
  }

  public editUser (user?: UserWithPlatformRole): void {
    if (!user) return
    this.userToEdit = user
    this.showEditUser = true
  }

  public onUserEditClosed (): void {
    this.userToEdit = null
    this.showEditUser = false
  }

  public async onUserEditConfirmed (editData: UserWithPlatformRole): Promise<void> {
    const oldUser: UserWithPlatformRole | null = this.userToEdit
    this.onUserEditClosed()

    if (!oldUser) return
    if (!!oldUser.platformRole && oldUser.platformRole !== editData.platformRole) {
      await UserStore.setUserPlatformRole({ id: editData.id, role: editData.platformRole })
    }
  }

  public async deleteUser (user: UserWithPlatformRole): Promise<void> {
    if (user.platformRole === Role.Anonymous) return
    const confirmation = await this.$confirm(`Are you sure you want to remove the following user from the platform:<br><br>${user.displayName}`, { title: 'Delete user?', icon: '' })
    if (!confirmation) return
    await UserStore.deleteUser(user.id)
  }
}
